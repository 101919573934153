import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, flatMap, map, shareReplay } from "rxjs/operators";
import { BlogArticle } from './blog-article.model';
import { BlogAuthor } from './blog-author.model';
import { BookOffer } from './book-offer.model';
import { compareDesc, formatDistance, parseISO, parseJSON, format } from 'date-fns';
import { SPORTS } from './sports';
import { LocalisationService } from './localisation.service';
import { HoneycombService } from './honeycomb.service';
import { GeneralService } from './general.service';
import { UserLocationData } from "./user-location-data.model";
import { environment } from 'src/environments/environment';
import { Match } from './match.model';
import { LandingPage } from './landing-page.model';

// const api = require("@opentelemetry/api");
// const tracer = require("tracing.ts");

@Injectable({
	providedIn: 'root'
})
export class StatsInsiderBlogService {

	readKey = "5px9a31VWUleoToX0AH7P0W3DasfsBBNO4qgYoblJCwNlbiSYI";
	adhesiveBannersVisible = true;

	autoFeatureSports = [/*"EPL", "NBA", "NFL",*/ "AFL", "NRL", /*"BBL",*/ "SOO"];

	userLocationData$: Observable<UserLocationData> = this.http.get<any>(`${environment.dimersGeoDomain}/v1/get_geolocation`)
		.pipe(
			map((response: any) => {
				if (response) {
					return (response as UserLocationData);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			shareReplay(1),
			catchError(this.handleError<UserLocationData>())
		);

	constructor(
		private http: HttpClient,
		private localisationService: LocalisationService,
		private honeycomb: HoneycombService,
		private generalService: GeneralService,
	) { }

	disableAdhesiveBanners(): void {
		this.adhesiveBannersVisible = false;
	}

	getArticle(slug: string, revisionID?: string): Observable<BlogArticle> {
		console.log('getArticle');
		let rno = Math.random()
		console.time('getArticle' + rno)
		const query = {
			"type": "stats-insider-articles",
			"slug": slug,
		};
		if (revisionID) {
			return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
				+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}&limit=1&props=id`
				+ `&read_key=${this.readKey}&status=any`)
			.pipe(
				flatMap((response1: any) => {
					console.log("gotten article:")
					console.timeLog('getArticle' + rno)
					if (response1.objects && response1.objects.length > 0) {
						const articleID = response1.objects[0].id;
						return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects/${articleID}/revisions/${revisionID}`
							+ `?read_key=${this.readKey}`)
					} else if (response1.errors) {
						throw new Error(response1.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				map((response2: any) => {
					if (response2.revision) {
						console.log("gotten revision:");
						console.timeLog('getArticle' + rno)
						const o = response2.revision;
						return {
							// TODO deal with autocon case
							article_category: "dimers_content" as "dimers_content",
							author: o.metadata.author.title,
							authors: [{
								id: o.metadata.author.id,
								last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
								slug: o.metadata.author.slug,
								first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
								social_username: o.metadata.author.metadata.twitter_username,
								description: o.metadata.author.content,
								short_bio: o.metadata.author.content,
								social_summary_title: o.metadata.author.title + " - Sports Contributor",
								social_summary_description: `The latest articles by ${o.metadata.author.title} on Stats Insider. Read data-driven sports content and news from the home of sports predictions.`,
								thumbnail: {
									dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
								},
							}],
							content_description: o.content,
							created_at: o.created_at,
							featured_article: null,
							id: o.id,
							published_date: null,
							published_date_readable: "Unpublished",
							unpublished_at: o.unpublish_at,
							short_title: o.title,
							slug: o.slug,
							socialThumbnail: {
								url: o.metadata.hero_image.url,
							},
							social_summary_description: o.metadata.preview_text,
							social_summary_title: o.title + "",
							sport_betting_category: null,
							summarized_description: o.metadata.preview_text,
							tags: o.metadata.categories.map(c => ({
								name: c,
								slug: c,
							})),
							thumbnail: {
								dynamic_url: o.metadata.hero_image.imgix_url,
								url: o.metadata.hero_image.url,
							},
							title: o.title,
							faqs: [],
							show_faq: false,
						};
					} else if (response2.errors) {
						throw new Error(response2.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				catchError(this.handleError<BlogArticle>())
			)
		} else {
			let o: any;
			return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
				+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
				+ `&limit=1&read_key=${this.readKey}`)
			.pipe(
				flatMap((mainResponse: any) => {
					console.log("gotten article id:")
					console.timeLog('getArticle' + rno)
					if (mainResponse.objects && mainResponse.objects.length > 0) {
						o = mainResponse.objects[0];
						const articleID = o.id;
						return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects/${articleID}/revisions?limit=1&sort=created_at&query=%7B"status"%3A"published"%7D&read_key=${this.readKey}`)
					} else if (mainResponse.errors) {
						throw new Error(mainResponse.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				map((revisionsResponse: any) => {
					console.log("gotten article revision:")
					console.timeLog('getArticle' + rno)
					if (revisionsResponse.revisions && revisionsResponse.revisions.length > 0) {
						const earliestPublished = revisionsResponse.revisions[0].created_at;
						{
							return {
								// TODO deal with autocon case
								article_category: "dimers_content" as "dimers_content",
								author: o.metadata.author.title,
								authors: [{
									id: o.metadata.author.id,
									last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
									slug: o.metadata.author.slug,
									first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
									social_username: o.metadata.author.metadata.twitter_username,
									description: o.metadata.author.content,
									short_bio: o.metadata.author.content,
									social_summary_title: o.metadata.author.title + " - Sports Contributor",
									social_summary_description: `The latest articles by ${o.metadata.author.title} on Stats Insider. Read data-driven sports content and news from the home of sports predictions.`,
									thumbnail: {
										dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
									},
								}],
								content_description: o.content,
								created_at: earliestPublished,
								featured_article: null,
								id: o.id,
								published_date: o.published_at,
								published_date_readable: formatDistance(parseJSON(o.published_at), new Date()) + " ago",
								unpublished_at: o.unpublish_at,
								short_title: o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.title + "",
								sport_betting_category: null,
								summarized_description: o.metadata.preview_text,
								tags: o.metadata.categories.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
								},
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						}
					} else if (revisionsResponse.errors) {
						throw new Error(revisionsResponse.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				catchError(this.handleError<BlogArticle>())
			)
		}
	}

	getStateId(state: string): Observable<string> {
        // TODO
		return of("");
    }

	getBestBooksById(stateId: string): Observable<Record<string, any>> {
        // TODO
		return of({});
    }

    getSportsBooksById(stateId: string): Observable<Array<Record<string, any>>> {
        // TODO
		return of([]);
    }

    getBestBooksPromos(stateId: string): Observable<Array<Record<string, any>>> {
        // TODO
		return of([])
    }

	getCosmicOffersByStateId(stateId: string) {
		// TODO
		return of([]);
	}

	getSportsbookReview(stateId: string) {
		// TODO
		return of({});
	}

    getSportsBooksReviewMethodology(): Observable<Record<string, any>> {
        // TODO
		return of({});
    }


	getLatestArticleSummaries(count: number): Observable<Array<BlogArticle>> {
		console.log('getLatestArticleSummaries');
		
		let rno = Math.random()
		console.time('getLatestArticleSummaries ' + rno)
		const query = {
			"type": "stats-insider-articles",
			"metadata.locale_exclusivity": {"$in": ["All Locales", this.localisationService.locale + " Exclusive", ""]}
		}
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
			+ `&limit=${count}&read_key=${this.readKey}&sort=-modified_at`)
		.pipe(
			map((response: any) => {
				if (response.objects) {
					// return (response.data as Array<BlogArticle>)
					// 	.sort((a,b) => compareDesc(
					// 		parseJSON(a.published_date),
					// 		parseJSON(b.published_date)
					// 	));
					console.log("latest article summaries retrieved:")
					console.timeLog('getLatestArticleSummaries '+rno)

					const articles = response.objects
						.sort((a,b) => compareDesc(parseISO(a.metadata.originally_published), parseISO(b.metadata.originally_published)));

					console.log("latest article summaries sorted:")
					console.timeLog('getLatestArticleSummaries '+rno)
					const transformedArticles = articles
						.map(o => {
								
							let combinedDate;
							let postDate = parseISO(o.metadata.originally_published);
							let timeDate = parseJSON(o.published_at);
							combinedDate = new Date(postDate.getFullYear(), postDate.getMonth(), postDate.getDate(),
								timeDate.getHours(), timeDate.getMinutes(), timeDate.getSeconds());
							return {
								// TODO deal with autocon case
								article_category: "dimers_content",
								author: o.metadata.author.title,
								authors: [{
									id: o.metadata.author.id,
									last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
									slug: o.metadata.author.slug,
									first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
									social_username: o.metadata.author.metadata.twitter_username,
									description: o.metadata.author.content,
									short_bio: o.metadata.author.content,
									social_summary_title: o.metadata.author.title + " - Sports Contributor",
									social_summary_description: `The latest articles by ${o.metadata.author.title} on Stats Insider. Read data-driven sports content and news from the home of sports predictions.`,
									thumbnail: {
										dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
									},
								}],
								content_description: o.content,
								created_at: o.created_at,
								featured_article: null,
								id: o.id,
								published_date: combinedDate.toJSON(),
								published_date_readable: formatDistance(combinedDate, new Date()) + " ago",
								unpublished_at: o.unpublish_at,
								short_title: o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.title + "",
								sport_betting_category: [],
								summarized_description: o.metadata.preview_text,
								tags: o.metadata.categories.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
								},
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						});
					
					console.log("latest article summaries transformed:")
					console.timeLog('getLatestArticleSummaries '+rno);
					return transformedArticles;
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogArticle>>())
		)
	}

	// TODO
	getBuilderPage(pageCode: string): Observable<LandingPage> {
		console.log('getBuilderPage');
		return of(null);
	}

	// TODO
	builderPageExists(pageCode: string): Observable<boolean> {
		console.log('builderPageExists');
		return of(false);
	}

    //excludeFeature parameter only used for SI(for horse radcing home page use only)
	// excludeFeature parameter is now ignored as featured designation is set based on category
	getLatestArticleSummariesByCategory(category: string, count: number, exceptID?: number, excludeFeature?: boolean): Observable<Array<BlogArticle>> {
		console.log('getLatestArticleSummariesByCategory');

		const queryExclFeature = {
			"type": "stats-insider-articles",
			"metadata.categories": category.toUpperCase(),
			// "metadata.nav_featured": {
			//     $ne: "Yes"
			// }
		}

        const query = {
			"type": "stats-insider-articles",
			"metadata.categories": category.toUpperCase()
		}

		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
			+ `query=${JSON.stringify(excludeFeature ? queryExclFeature : query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
			+ `&limit=${count}&read_key=${this.readKey}&sort=-modified_at`)
		.pipe(
			map((response: any) => {
				if (response.objects) {
					// return (response.data as Array<BlogArticle>)
					// 	.sort((a,b) => compareDesc(
					// 		parseJSON(a.published_date),
					// 		parseJSON(b.published_date)
					// 	));
					return response.objects
						.sort((a,b) => compareDesc(parseISO(a.published_at), parseISO(b.published_at)))
						.map(o => {
								
							let combinedDate;
							let postDate = parseISO(o.metadata.originally_published);
							let timeDate = parseJSON(o.published_at);
							combinedDate = new Date(postDate.getFullYear(), postDate.getMonth(), postDate.getDate(),
								timeDate.getHours(), timeDate.getMinutes(), timeDate.getSeconds());
							return {
								// TODO deal with autocon case
								article_category: "dimers_content",
								author: o.metadata.author.title,
								authors: [{
									id: o.metadata.author.id,
									last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
									slug: o.metadata.author.slug,
									first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
									social_username: o.metadata.author.metadata.twitter_username,
									description: o.metadata.author.content,
									short_bio: o.metadata.author.content,
									social_summary_title: o.metadata.author.title + " - Sports Contributor",
									social_summary_description: `The latest articles by ${o.metadata.author.title} on Stats Insider. Read data-driven sports content and news from the home of sports predictions.`,
									thumbnail: {
										dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
									},
								}],
								content_description: o.content,
								created_at: o.created_at,
								featured_article: this.autoFeatureSports.some(c => o.metadata.categories.includes(c)),
								id: o.id,
								published_date: combinedDate.toJSON(),
								published_date_readable: formatDistance(combinedDate, new Date()) + " ago",
								unpublished_at: o.unpublish_at,
								short_title: o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.title + "",
								sport_betting_category: [],
								summarized_description: o.metadata.preview_text,
								tags: o.metadata.categories.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
								},
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						});
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogArticle>>())
		)
	}

	getFeaturedArticleSummaries(count: number, category?: string): Observable<Array<BlogArticle>> {
		// console.log('getFeaturedArticleSummaries');
		let rno = Math.random()
		// console.time('getFeaturedArticleSummaries ' + rno)
		const query = {
			"type": "stats-insider-articles",
			// "metadata.nav_featured": "Yes",

			// if no category selected, articles are deemed featured if they are in one of the "auto-feature" sports
			"metadata.categories": category ? category.toUpperCase() : {
				"$in": this.autoFeatureSports
			},
			"metadata.locale_exclusivity": {"$in": ["All Locales", this.localisationService.locale + " Exclusive", ""]}
		}
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}&props=title,slug,published_at,metadata`
			+ `&limit=${count}&read_key=${this.readKey}&sort=-modified_at`)
		
		.pipe(
			map((response: any) => {
				// console.log(response)

				// let span = tracer.startSpan("expensive-query");

				if (response.objects) {
					this.honeycomb.sendMessage({
						message: `finished retrieval from blog service`
					});
			
					console.log("featured article summaries retrieved:")
					// console.timeLog('getFeaturedArticleSummaries '+rno)

					const articles = response.objects
						// .sort((a,b) => compareDesc(parseISO(a.metadata.originally_published), parseISO(b.metadata.originally_published)));

					console.log("featured article summaries sorted:")
					// console.timeLog('getFeaturedArticleSummaries '+rno)
					const transformedArticles = articles
						.map(o => {
								
							let combinedDate;
							let postDate = parseISO(o.metadata.originally_published);
							let timeDate = parseJSON(o.published_at);
							combinedDate = new Date(postDate.getFullYear(), postDate.getMonth(), postDate.getDate(),
								timeDate.getHours(), timeDate.getMinutes(), timeDate.getSeconds());
							return {
								// TODO deal with autocon case
								article_category: "dimers_content",
								author: o.metadata.author.title,
								authors: [{
									id: o.metadata.author.id,
									last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
									slug: o.metadata.author.slug,
									first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
									social_username: o.metadata.author.metadata.twitter_username,
									description: o.metadata.author.content,
									short_bio: o.metadata.author.content,
									social_summary_title: o.metadata.author.title + " - Sports Contributor",
									social_summary_description: `The latest articles by ${o.metadata.author.title} on Stats Insider. Read data-driven sports content and news from the home of sports predictions.`,
									thumbnail: {
										dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
									},
								}],
								content_description: o.content,
								created_at: o.created_at,
								featured_article: null,
								id: o.id,
								published_date: combinedDate.toJSON(),
								published_date_readable: formatDistance(combinedDate, new Date()) + " ago",
								unpublished_at: o.unpublish_at,
								short_title: o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.title + "",
								sport_betting_category: [],
								summarized_description: o.metadata.preview_text,
								tags: o.metadata.categories.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
								},
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						}) as Array<BlogArticle>;
					
					console.log("featured article summaries converted:")
					// console.timeLog('getFeaturedArticleSummaries '+rno);
					return transformedArticles;
					// .sort((a,b) => a.featured_article?.order_index - b.featured_article?.order_index);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
            catchError(error => {
                if (error.status === 404) {
                    return of([]); // Return empty data for 404 errors
                }
                  throw error;
            })
		)
	}

	getAuthors(): Observable<Array<BlogAuthor>> {
		console.log('getAuthors');
		const query = {
			"type": "authors",
		}
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
			+ `&read_key=${this.readKey}&props=id,title,slug,content,metadata`)
		.pipe(
			map((response: any) => {
				if (response.objects) {
					return response.objects.filter(a => a.metadata.writes_for.includes("Stats Insider")).map((o: Record<string, any>) => ({
						id: o.id,
						last_name: (o.metadata.author_first_name && o.title.includes(o.metadata.author_first_name)) ? o.title.replace(o.metadata.author_first_name, "").trim() : o.title,
							slug: o.slug,
							first_name: (o.metadata.author_first_name && o.title.includes(o.metadata.author_first_name)) ? o.metadata.author_first_name : "",
							social_username: o.metadata.twitter_username,
						description: o.content,
						short_bio: o.content,
						social_summary_title: o.title + " - Sports Contributor",
						featured_candidate: o.metadata.featured_candidate.includes("Yes"),
						social_summary_description: `The latest articles by ${o.title} on Stats Insider. Read data-driven sports content and news from the home of sports predictions.`,
						thumbnail: {
							dynamic_url: o.metadata.author_thumbnail.imgix_url,
						},
					}));
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogAuthor>>())
		)
	}

	getAuthor(slug: string): Observable<BlogAuthor> {
		console.log('getAuthor');
		const query = {
			"type": "authors",
			"slug": slug,
		}
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?query=%7B%22type%22%3A%22authors%22%2C%22slug%22%3A%22${slug}%22%7D`
			+ `&read_key=${this.readKey}&props=id,slug,title,content,metadata`)
		.pipe(
			map((response: any) => {
				if (response.objects?.length > 0) {
					let author = response.objects[0];
					return {
						id: author.id,
						last_name: (author.metadata.author_first_name && author.title.includes(author.metadata.author_first_name)) ? author.title.replace(author.metadata.author_first_name, "").trim() : author.title,
						slug: author.slug,
						first_name: (author.metadata.author_first_name && author.title.includes(author.metadata.author_first_name)) ? author.metadata.author_first_name : "",
						social_username: author.metadata.twitter_username,
						description: author.content,
						short_bio: author.content,
						social_summary_title: author.title + " - Sports Contributor",
						social_summary_description: `The latest articles by ${author.title} on Stats Insider. Read data-driven sports content and news from the home of sports predictions.`,
						thumbnail: {
							dynamic_url: author.metadata.author_thumbnail.imgix_url,
						},
					};
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<BlogAuthor>())
		)
	}

	getArticleSummariesPage(authorID: string, category: string, query: string, skip: number = 0, count: number): Observable<{articles: Array<BlogArticle>, totalCount: number}> {
		const queryObject = {
			"type": "stats-insider-articles",
			"metadata.author": authorID ? authorID : undefined,
			"metadata.categories": category ? category.toUpperCase() : undefined,
			"metadata.locale_exclusivity": {"$in": ["All Locales", this.localisationService.locale + " Exclusive", ""]},
			"$or": query ? [
				{"title": {"$regex": query, "$options": "i"}},
				{"content": {"$regex": query, "$options": "i"}}
			] : undefined,
		}
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
			+ `limit=${count}&query=${JSON.stringify(queryObject).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}&props=title,slug,published_at,metadata`
			+ `&read_key=${this.readKey}&sort=-modified_at&skip=${skip}`)
		.pipe(
			map((response: any) => {
				if (response && response.objects && response.total) {
                    
					return {articles: (response.objects
						.sort((a,b) => compareDesc(parseISO(a.published_at), parseISO(b.published_at)))
                        .map(o => {
							
						let combinedDate;
						let postDate = parseISO(o.metadata.originally_published);
						let timeDate = parseJSON(o.published_at);
						combinedDate = new Date(postDate.getFullYear(), postDate.getMonth(), postDate.getDate(),
							timeDate.getHours(), timeDate.getMinutes(), timeDate.getSeconds());
						return {
							// TODO deal with autocon case
							article_category: "dimers_content",
							author: o.metadata.author.title,
							authors: [{
								id: o.metadata.author.id,
								last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
								slug: o.metadata.author.slug,
								first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
								social_username: o.metadata.author.metadata.twitter_username,
								description: o.metadata.author.content,
								short_bio: o.metadata.author.content,
								social_summary_title: o.metadata.author.title + " - Sports Contributor",
								social_summary_description: `The latest articles by ${o.metadata.author.title} on Stats Insider. Read data-driven sports content and news from the home of sports predictions.`,
								thumbnail: {
									dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
								},
							}],
							content_description: o.content,
							created_at: o.created_at,
							featured_article: null,
							id: o.id,
							published_date: combinedDate.toJSON(),
							published_date_readable: formatDistance(combinedDate, new Date()) + " ago",
							unpublished_at: o.unpublish_at,
							short_title: o.title,
							slug: o.slug,
							socialThumbnail: {
								url: o.metadata.hero_image.url,
							},
							social_summary_description: o.metadata.preview_text,
							social_summary_title: o.title + "",
							sport_betting_category: null,
							summarized_description: o.metadata.preview_text,
							tags: o.metadata.categories.map(c => ({
								name: c,
								slug: c,
							})),
							thumbnail: {
								dynamic_url: o.metadata.hero_image.imgix_url,
								url: o.metadata.hero_image.url,
							},
							title: o.title,
							faqs: [],
							show_faq: false,
						}
					}) as Array<BlogArticle>), totalCount: (response.total as number)};
				} else if (response && (response.objects === null || !response.total)) {
					return {articles: [], totalCount: 0};
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<{articles: Array<BlogArticle>, totalCount: number}>())
		)
	}

	getAuthorSummariesPage(authorSlug: string, skip: number, count: number) {
		return of([]);
	}

	// TODO
	getAdhesiveBanner(stateCode: string): Observable<Record<string, any>> {
		console.log('getAdhesiveBanner');
		return of({});
	}

	// getLatestArticleCategories(): Observable<Array<string>> {
	// 	return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?query=%7B%22type%22%3A%22stats-insider-articles%22%7D&limit=1&read_key=${this.readKey}&props=metafields`)
	// 	.pipe(
	// 		map((response: any) => {
	// 			if (response && response.objects && response.objects[0]
	// 				&& response.objects[0].metafields && response.objects[0].metafields.some(m => m.key === "categories")) {
	// 				return response.objects[0].metafields.find(m => m.key === "categories").options.map(o => o.value);
	// 			} else if (response.errors) {
	// 				throw new Error(response.errors[0].message)
	// 			} else {
	// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 			}
	// 		}),
	// 		catchError(this.handleError<Array<Record<string, any>>>())
	// 	)
	// }

	// TODO
	getWelcomeOffers(state: string, count?: number): Observable<Array<BookOffer>> {
		console.log('getWelcomeOffers');
		return of([]);
	}

	// TODO
	getBestOffers(count: number, state: string, sportCode?: string): Observable<Array<BookOffer>> {
		console.log('getBestOffers');
		return of([]);
	}

	// TODO
	getUserLocationData(): Observable<UserLocationData> {
		return of(null);
	}

	getAppSettings(pageCode: string): Observable<Record<string, any>> {
		let rno = Math.random()
		console.log('getAppSettings');
		console.time('getAppSettings' + rno);
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?query=%7B%22type%22%3A%22page-meta-objects%22%2C%22slug%22%3A%22${pageCode}%22%7D&read_key=${this.readKey}&props=slug,title,content,metadata`)
		.pipe(
			map((response: any) => {
				if (response.objects) {
					console.log("Page meta returned:")
					console.timeLog('getAppSettings' + rno)
					const returnedObject = {
						...response.objects[0],
						...response.objects[0].metadata,
						app_title: response.objects[0].title,
						page_title: response.objects[0].title,
						thumbnail: {
							url: response.objects[0].metadata?.meta_image?.url || undefined,
						}
					};
					console.log("Page meta converted:");
					console.timeLog('getAppSettings' + rno)
					return returnedObject;
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getBetHubSettings(sportCode: string): Observable<Record<string, any>> {
		console.log('getBetHubSettings');
		let rno = Math.random()
		console.time('getBetHubSettings' + rno)
		let title: string, description: string, metaImageURL: string, page_description: string = null, subheading_description: string = null;
		let faqs: Array<{headerText: string, bodyHTML: string}> = [];
		console.timeLog('getBetHubSettings' + rno)
		switch (sportCode.toUpperCase()) {
			case "TEN":
				title = "Tennis Tournament Match Predictions | Schedule | Tennis Games";
				description = "Tennis predictions for every match. Who will win? Get the latest probabilities from 10,000 match simulations by our ball-by-ball predictive model.";
				// metaImageURL = "https://cdn.ciphersports.io/images/tennis_hub_meta.jpg";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				page_description = "We run 10,000 simulations per tennis match to predict the most likely outcomes and detect value against the markets. Get ATP and WTA tennis betting tips here. Click or tap on any game below.";
				subheading_description = `<h2>Free Tennis Betting Tips</h2>
				<p>Stats Insider has tennis predictions today for every tennis match. Based on 1000s of simulations, our computer analyses the data for each and every match in the ATP and WTA tours, including all four grand slam tournaments, to provide free tennis betting tips for you to bet on and become an expert.</p>
				`;
				break;
			case "BBL":
				title = "BBL 2023-24 Cricket Match Predictions | Big Bash League Schedule | Games"
				description = "BBL predictions for every match. Who will win? Get the latest Big Bash projections from the home of advanced sporting analytics and machine-learning model predictions."
				// metaImageURL = "https://cdn.ciphersports.io/images/bbl_hub_meta.jpg";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				page_description = "We run 10,000 simulations per BBL game to predict the most likely outcomes and detect value against the markets. Get BBL betting tips here. Click or tap on any game below.";
				subheading_description = `<h2>Free Big Bash Betting Tips</h2>
				<p>Stats Insider has BBL predictions this week for every BBL game. Based on 1000s of simulations, our computer analyses the data for each and every match in the BBL home 'n' away season and finals series, to provide free Big Bash League betting tips for you to bet on and become an expert.</p>
				`;
				break;
			case "EPL":
				title = "EPL Match Predictions | EPL Games 2024-25 | Fixtures"
				description = "Welcome to Stats Insider's Premier League match predictions. Powered by our cutting-edge computer modeling algorithm, our data-driven predictions result from thousands of simulations for every English Premier League game, offering you top-tier insights and EPL Best Bets. Whether you're a newcomer to English football or a dedicated fan, Stats Insider's Premier League predictions are second to none."
				metaImageURL = "https://cdn.ciphersports.io/images/epl_hub_meta.jpg";
				page_description = `Welcome to Stats Insider's Premier League match predictions. Powered by our cutting-edge computer modeling algorithm, our data-driven predictions result from thousands of simulations for every English Premier League game, offering you top-tier insights and <a href='/best-bets/epl' target='blank'>EPL Best Bets</a>. Whether you're a newcomer to English football or a dedicated fan, Stats Insider's Premier League predictions are second to none.`;
				subheading_description = `
				<h2>English Premier League Predictions for Every Fixture</h2>

				<p>On the lookout for dependable English Premier League predictions each and every gameweek? Your search
				ends here at Stats Insider, your ultimate resource for data-driven analysis and insights on the English
				Premier League. Harnessing the power of machine learning, our English Premier League model employs advanced
				analytical techniques and predictive modeling methods to provide precise and reliable EPL predictions, and
				English Premier League best bets.</p>
				
				<p>At Stats Insider, we fully grasp the complexity and ever-evolving nature of English Premier League betting,
				with a plethora of factors influencing match outcomes. That's why we eliminate opinion and operate solely on
				concrete facts. From team performance metrics to player statistics and injury updates, even the most minor
				detail is taken into account when providing English Premier League predictions and EPL best bets. Our team
				of data experts stays up-to-date with the latest league developments, injury reports, and everything in
				between, ensuring that our Premier League predictive model is armed with the best available data. So, if
				you're in search of top-notch guidance in Premier League betting, <a href="/sport-hub/epl/futures"
				target="_blank">EPL futures</a> and match predictions, look
				no further than Stats Insider.</p>
				
				<h2>Our English Premier League Prediction Process</h2>
				
				<p>At Stats Insider, our English Premier League tips stem from 10,000 simulations of each match conducted
				by our EPL predictive model. Our model takes into account an array of elements, including team form, player
				injuries, home-field advantage, and up to the minute <a href="/news?sport=epl" target="_blank">EPL news</a>
				along with other pertinent variables, guaranteeing that
				our English Premier League predictions are EPL best bets both reliable and well-informed. While other platforms
				offer Premier League tips rooted in opinion and inherent bias, our predictions are objective, removing emotion
				from the equation.</p>
				
				<h2>Understanding English Premier League Tips</h2>
				
				<p>English Premier League tips encompass forecasts generated by our predictive model. Our model runs
				10,000 simulations for every EPL match, producing predictions for each fixture spanning the regular
				season and beyond. These EPL tips stand out in terms of reliability and precision when compared to
				suggestions found on other websites, as our English Premier League best bets leverage Stats Insider's
				data-driven analytic model, which simulates each match 10,000 times.</p>
				
				<h2>Why Choose Stats Insider's English Premier League Betting Advice?</h2>
				
				<p>Stats Insider's English Premier League betting advice distinguishes itself from our competitors
				due to our data-focused approach. Utilising an array of data analysis tools and techniques - including
				statistical analysis, form evaluation, and performance scrutiny - we provide the most accurate and
				dependable advice for every match.
				
				`;
				faqs = [
					{headerText: "What Sets Stats Insider's English Premier League Predictions Apart?", bodyHTML: "The reliability of Stats Insider's Premier League predictions stems from our data-driven methodology. We employ an advanced computer modeling algorithm that subjects each Premier League match to 10,000 simulations, factoring in aspects like team form, player injuries, and home-field advantage. This thorough analysis guarantees the accuracy and well-informed nature of our predictions."},
					{headerText: "How Do Stats Insider's English Premier League Predictions Differ from Conventional Tips?", bodyHTML: "Stats Insider's English Premier League predictions stand out by arising from our data-based analytic model. This model runs over 10,000 simulations for every Premier League match, ensuring a higher level of reliability and precision compared to standard tips found on other platforms."},
					{headerText: "Why Opt for Stats Insider's English Premier League Tips?", bodyHTML: "Opting for Stats Insider's Premier League betting tips is a smart choice due to our comprehensive data-driven approach. We leverage a variety of data analysis tools - statistical, form-based, and performance-oriented - to provide the most accurate and dependable predictions for each Premier League match."},
					
				];
				break;
			case "ESP":
				title = "La Liga 2024-25 Match Predictions | Games | Fixtures"
				description = "La Liga ptredictions for every match of the season. Who will win? Get the latest probabilities from the home of advanced sporting analytics and machine-learning model predictions."
				metaImageURL = "https://cdn.ciphersports.io/images/esp_hub_meta.jpg";
				page_description = "We run 10,000 simulations per La Liga game to predict the most likely outcomes and detect value against the markets. Get La Liga betting tips here. Click or tap on any game below.";
				subheading_description = `<h2>Free La Liga Betting Tips</h2>
				<p>Stats Insider has La Liga predictions this week for every La Liga game. Based on 1000s of simulations, our computer analyses the data for each and every match in the La Liga season, to provide free La Liga betting tips for you to bet on and become an expert.</p>
				`;
				break;
			case "ALG":
				title = "A-League 2020-21 Match Predictions | Games | Fixtures"
				description = "A-League predictions for every match of the season. Who will win? Get the latest probabilities from the home of advanced sporting analytics and machine-learning model predictions."
				metaImageURL = "https://cdn.ciphersports.io/images/alg_hub_meta.jpg";
				break;
			case "AFL":
				title = "AFL Expert Betting Tips 2024 | AFL Predictions for Every Game"
				description = "Stay ahead of the competition with our AFL expert betting tips and predictions for every game in 2024."
				// metaImageURL = "https://cdn.ciphersports.io/images/afl_hub_meta.jpg";
				metaImageURL = "htps://cdn.ciphersports.io/images/default_meta.jpg";
				page_description = "Welcome to our AFL expert betting tips page for 2024. Our team of seasoned tipsters is dedicated to providing you with the best tips and AFL predictions for every game this season. Whether you're an experienced AFL punter or just starting out, our expert analysis and insights will help you make the best AFL bets.";
				subheading_description = `
				<h2>AFL predictions for every game in 2024</h2>
				<p>Looking for reliable AFL predictions for every game in 2024? Look no further than Stats Insider, your go-to source for expert analysis and insights into the Australian Football League.</p>
				<p>Our team of experienced analysts and statisticians uses advanced data analysis and predictive modeling techniques to deliver accurate and reliable AFL predictions for every game of the season. Whether you're a seasoned punter or a casual fan looking to get in on the action, our insights can help you make informed bets and stay ahead of the game.</p>
				<p>At Stats Insider, we understand that AFL betting can be a complex and ever-changing landscape, with a multitude of factors influencing the outcome of each game. That's why we combine our deep understanding of the sport with cutting-edge data analysis techniques to deliver AFL best bets and tips that are both accurate and reliable.</p>
				<p>From team performance data to player stats and injury updates, we leave no stone unturned in our quest to deliver the most comprehensive and accurate AFL predictions and tips possible. Our team of experts is dedicated to staying up-to-date with the latest developments in the league, so you can be confident that you're getting the most current and accurate insights available.</p>
				<p>So if you're looking for the best AFL tipping advice and predictions for every game in 2024, look no further than Stats Insider. With our proven track record of success and deep expertise in the world of AFL betting, we're confident that we can help you stay ahead of the game and make smart, informed bets that give you the best chance of winning. So why wait? Sign up for our newsletter today and start receiving the best AFL predictions and tips around!</p>

				<h2>How we make our AFL predictions</h2>
				At Stats Insider, our AFL expert betting tips are produced by our team of experienced analysts using a rigorous methodology to provide the most accurate AFL predictions for every game. We take into account a wide range of factors such as team form, player injuries, home ground advantage and weather conditions. Stats Insider also uses data analysis tools and techniques to study team performance and identify trends and patterns that can help us make more informed predictions.

				<h2>What are AFL expert tips?</h2>
				AFL expert tips are predictions made by specialists who have a deep understanding of the AFL. Our team of experienced AFL tipsters has years of experience and expertise in predicting AFL games. These tips are more reliable and accurate than regular tips because they utilise Stats Insider's independent analytic model, which simulates every game over 10,000 times.

				<h2>Why choose our AFL betting tips?</h2>
				Stats Insider's AFL expert betting tips are the best in the market because our team of analysts has years of experience and expertise in predicting AFL games. We use a variety of data analysis tools and techniques, including statistical analysis, form analysis, and performance analysis, to provide the most accurate and reliable tips for every game. Our AFL tips are designed to help you make informed betting decisions based on the latest data and insights.
				`;
				faqs = [
					{headerText: "How do I use AFL expert tips to improve my betting strategy?", bodyHTML: "Using Stats Insider’s predictive models and AFL expert tips is the best way to improve your betting strategy. Stats Insider simulates every game over 10,000 times with industry-leading computer technology to help maximise your AFL best bets."},
					{headerText: "How can I stay up to date with the latest AFL news and trends?", bodyHTML: "To stay up to date with the latest AFL news and trends when it comes to games and predictions, visit Stats Insider. Stats Insider covers every game of the AFL season and delivers predictions based on data-driven analytics."},
					{headerText: "What types of AFL markets do you cover in your tips?", bodyHTML: "Our AFL tips cover a range of markets, including the match winner, margin, and total points. We also provide detailed analysis for each game, including key player matchups and team statistics. Our tips are designed to help you make informed betting decisions based on the latest data and insights."},
					{headerText: "How often are your AFL tips updated?", bodyHTML: "Our AFL tips are constantly updated and ever-evolving. A myriad of factors go into our AFL tips and predictions with everything from player availabilities, team news and even weather factoring in to our simulations."},
				];
				break;
			case "NRL":
				title = "NRL Expert Betting Tips 2024 | NRL Predictions for Every Game"
				description = "Stay ahead of the competition with our NRL expert betting tips and predictions for every game in 2024."
				// metaImageURL = "https://cdn.ciphersports.io/images/nrl_hub_meta.jpg";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				page_description = "Are you an NRL fan looking for expert betting tips and predictions for every game in 2024? Stats Insider has you covered. Our team of experts uses data analysis tools and techniques to provide the most accurate and reliable tips for every game throughout the season. By following our expert betting tips and predictions, you can increase your chances of winning and make the most of your NRL betting experience.";
				subheading_description = `
				<h2>NRL predictions for every game in 2024</h2>
				<p>Stats Insider delivers NRL predictions for every game in 2024. Our team of experts constantly analyses upcoming NRL matches to come up with the most accurate betting tips and predictions for each game.</p>
				<p>If you are looking to place NRL bets and find NRL tips for this weekend, Stats Insider is the place for you.  Our predictions are based on a combination of statistical analysis, team news, and our experts' extensive knowledge of the game.</p>
				<p>Taking advantage of our data modeling, and our expert NRL betting tips, you can make informed decisions. </p>
				<p>Stats Insider detailed predictions for every game of the 2024 NRL season so you're never left guessing when it comes to placing your NRL best bets.</p>
				<p>Our NRL predictions cover a range of markets, including match winner, total points, and first try scorer. We also provide analysis and insights into each team's form and key players to watch out for.</p>
				<p>Whether you're an experienced NRL bettor or a betting novice, our page is the ultimate resource for NRL betting tips and predictions. </p>
				<p>Don't forget to check back regularly for updates and the latest NRL betting tips and predictions. We'll be covering every game throughout the season, so you can stay ahead of the game.</p>

				<h2>How we make our NRL predictions</h2>
				<p>Stats Insider's team of analysts uses a rigorous methodology to make our NRL predictions. We consider a range of factors, including team form, player injuries, home advantage, and weather conditions, to provide the most accurate and reliable tips for every game. We use industry-leading data analysis tools and techniques to study team performance and identify trends and patterns that can help us make more informed predictions.</p>

				<h2>What are NRL expert tips?</h2>
				<p>NRL expert tips are predictions made by experts who use data analysis tools and techniques to study the form and performance of teams, as well as the conditions and circumstances surrounding each game. These tips are more accurate and reliable than regular tips because they are based on in-depth analysis and research.</p>

				<h2>Why choose our NRL betting tips?</h2>
				<p>Stats Insider's NRL expert betting tips are the best to use because our team of analysts has years of experience and expertise in predicting NRL games. We use a variety of data analysis tools and techniques, including statistical analysis, form analysis, and performance analysis, to provide the most accurate and reliable tips for every game.</p>

				`;
				faqs = [
					{headerText: "How often do you update your predictions?", bodyHTML: "Our predictions are constantly evolving based on a number of factors, including injuries, team form and even weather. Our data-driven model is constantly updating to make sure you can gain an edge with your NRL bets."},
					{headerText: "How do I use your NRL tips?", bodyHTML: "You can use our tips to inform your betting decisions. We provide NRL tips for every game of every round of the 2024 NRL season."},
					{headerText: "What happens if unexpected events affect the outcome of a game?", bodyHTML: "While we use a rigorous methodology to make our predictions, unexpected events can sometimes affect the outcome of a game. In such cases, we will update our predictions as soon as possible to reflect the latest information."},
				];
				break;
			case "CFB":
				title = "2023 College Football Predictions | NCAA Schedule | Games"
				description = "College Football predictions for every match. Who will win? Get the latest probabilities from the home of advanced sporting analytics and machine-learning model predictions."
				metaImageURL = "https://cdn.ciphersports.io/images/default_social.png";
				page_description = "We run 10,000 simulations per College Football game to predict the most likely outcomes and detect value against the markets. Get College Football betting tips here. Click or tap on any game below.";
				subheading_description = `<h2>Free College Football Betting Tips</h2>
				<p>Stats Insider has College Football predictions this week for every College Football game. Based on 1000s of simulations, our computer analyses the data for each and every match in the College Football regular season and playoffs, including the National Championship, to provide free College Football betting tips for you to bet on and become an expert.</p>
				`;
				break;
			case "SOO":
				title = "State of Origin Predictions"
				description = `State of Origin is the annual series played between Queensland and New South Wales,
				featuring the best players from the NRL. Our State of Origin predictions and tips are
				based on data-driven predictive analytics.`;
				metaImageURL = "https://cdn.ciphersports.io/images/default_social.png";
				page_description = `State of Origin is the annual series played between Queensland and New South Wales,
				featuring the best players from the NRL. Our State of Origin predictions and tips are
				based on data-driven predictive analytics.`;
				subheading_description = `
					<h2>State of Origin Series 2024 Overview</h2>
					<h3>What is the State of Origin series?</h3>
					<p>State of Origin is a best-of-three rugby league series played annually between two Australian state
					representative sides, the New South Wales Blues and the Queensland Maroons. Played in the middle of
					every <a href="https://www.nrl.com/">NRL</a> season, some of the best players from the competition form the two state sides, making it the
					pinnacle of rugby league each and every year.</p>
					<h3>State of Origin: A Brief History</h3>
					<p>Debuting in 1980, State of Origin is one of the must-see events on the Australian sporting calendar,
					attracting millions of viewers worldwide. The NSW and Queensland rivalry is among the fiercest in
					world sport, with the State of Origin shield among the most prized possessions in Australian sport.</p>
					<h3>Men's State of Origin Series 2024 Schedule</h3>
					<ul>
						<li aria-level="1"><strong>Game 1:</strong> Accor Stadium, Wednesday, June 5</li>
						<li aria-level="1"><strong>Game 2:</strong> Melbourne Cricket Ground, Wednesday, June 26</li>
						<li aria-level="1"><strong>Game 3:</strong> Suncorp Stadium, Wednesday, July 17</li>
					</ul>
					<h2>Stats of Origin: Tips and Analysis</h2>
					<h3>New South Wales Blues</h3>
					<p>After losing the 2022 and 2023 series, the New South Wales Blues are attempting to win back the
					State of Origin shield. NSW will be as determined as ever in the 2024 series thanks to their abundance
					of stars and desire to make up for their State of Origin loss from the previous two years.</p>
					<h3>Queensland Maroons</h3>
					<p>Billy Slater's Queensland Maroons will enter the 2024 series looking to win three straight State of Origin
					series for the first time since 2017. The Maroons have a number of up-and-coming stars that will complement
					their savvy veterans, making them a force to be reckoned with in State of Origin 2024.</p>
					<h2>Key Players to Watch</h2>
					<h3>NSW Key Players</h3>
					<p>Latrell Mitchell and Dylan Edwards are the ones to watch on the Blues team. With both players displaying quality
					form at club level, NSW will be relying on both men to bring their best to State of Origin 2024.</p>
					<h3>Queensland Key Players</h3>
					<p>Reece Walsh and Daly Cherry-Evans are the Maroons' key players. Walsh in particular has been dynamic for the
					Brisbane Broncos at club level, and coach Billy Slater will be hoping he can replicate that at State of Origin
					level.</p>
					<h2>State of Origin Predictions 2024</h2>
					<h3>New South Wales Blues' Prospects</h3>
					<p>The Blues will head into State of Origin 2024 hopeful they can return to winning ways. With a few
					selection shocks and returning stars coming back into the fold, NSW will be hoping they can deliver their best
					football on the big stage.</p>
					<h3>Queensland Maroons' Prospects</h3>
					<p>The Maroons will be looking to continue their great Origin form. There are a number of Queensland stars that
					are coming into their best form at the right time, which makes the Maroons a tough proposition heading into State
					of Origin 2024.</p>
					<h2>Understanding Our Origin Predictions</h2>
					<p>Stats Insider delivers predictions for all three games of the 2024 State of Origin series. Our expert team
					provides the most accurate betting tips, using a combination of statistical analysis, team news and extensive
					game knowledge.</p>
					<p>For placing State of Origin bets and finding tips, Stats Insider is your go-to resource. Our detailed
					predictions cover game winners, total points and first try scorers, along with insights into each team's form
					and key players.</p>
					<p>Whether you're an experienced bettor or new to betting, Stats Insider offers the ultimate State of Origin tips
					and predictions. Check back regularly for updates and the latest betting tips throughout the series to stay ahead
					of the game.</p>
					<h3>How We Make Our State of Origin Predictions</h3>
					<p>Stats Insider's team of data scientists and analysts uses a rigorous methodology to make our State of Origin
					predictions. We consider a range of factors, including team form, player injuries, home advantage and weather
					conditions, to provide the most accurate and reliable tips for every game. We use industry-leading data analysis
					tools and techniques to study team performance and identify trends and patterns that can help us make more informed
					predictions.</p>
					<h3>Why Choose Our State of Origin Betting Tips?</h3>
					<p>Stats Insider's State of Origin expert betting tips are the best to use because our team of data scientists and
					analysts has years of experience and expertise in predicting State of Origin games. We use a variety of data
					analysis tools and techniques, including statistical analysis, form analysis and performance analysis, to provide
					the most accurate and reliable tips for every game.</p>
					<p>If you decide to use our Origin predictions for betting purposes, it's important that you gamble responsibly
					and manage your finances effectively. For free and confidential support, call 1800 858 858 or visit
					<a href="https://www.gamblinghelponline.org.au/">gamblinghelponline.org.au</a>.</p>
				`;
				break;
			case "NFL":
				
				title = "NFL 2023 Match Predictions | Schedule | Games"
				description = "Welcome to Stats Insider's NFL predictions. Utilising our cutting-edge computer modeling algorithm, our data-driven predictions are the result of thousands of simulations for each NFL game, offering you the best NFL tips. Whether you are new to the NFL or a die-hard, Stats Insider's NFL predictions are for you."
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				page_description = `Welcome to Stats Insider's NFL predictions. Utilising our cutting-edge computer modeling algorithm, our data-driven predictions are the result of thousands of simulations for each NFL game, offering you the best NFL tips. Whether you are new to the NFL or a die-hard, Stats Insider's NFL predictions are for you.`;
				subheading_description = `
					<h2>NFL Predictions for Every Game</h2>

					<p>Searching for dependable <b>NFL predictions</b> for every game? Look no further than Stats Insider, your ultimate resource for data-driven analysis
					and insights on the NFL. Harnessing the power of machine learning, our NFL model employs advanced analysis and predictive modeling methodologies to
					provide precise and trustworthy <b>NFL predictions for each NFL game</b>. Whether you're a seasoned bettor or a casual enthusiast new to sports
					wagering, our insights can help you make smarter NFL bets.</p>

					<p>At Stats Insider, we are acutely aware of the complexity and ever-shifting nature of NFL betting, with a myriad of factors influencing game
					outcomes. This is why we eliminate any subjectivity and rely only on the facts. From team performance metrics to player statistics and injury
					updates, no detail goes unchecked in our quest to deliver the most comprehensive and precise predictions and
					<a href="/best-bets/nfl" target="_blank">NFL best bets</a>. Our team of data scientists stay up-to-date with the latest league developments,
					injury reports, and everything in-between, ensuring our NFL predictive model is fully equipped with the best possible data. This means if
					you are in pursuit of optimal NFL betting guidance and game predictions, look no further than Stats Insider.</p>

					<h2>Our NFL Prediction Process</h2>

					<p>At Stats Insider, our <b>NFL predictions result from 10,000 simulations of each game</b> carried out by our NFL predictive model.
					This model factors in an array of elements, such as team form, player injuries, home-field advantage, and other pertinent variables,
					ensuring our predictions for upcoming games, as well as <a href="/sport-hub/nfl/futures" target="_blank">NFL futures</a> are both
					dependable and well-informed. While other platforms provide NFL tips rooted in opinion, our NFL predictions are objective and take
					emotion out of the process.</p>

					<h2>Understanding NFL Tips</h2>

					<p><b>NFL tips</b> encompass predictions born from our predictive model. Our model runs 10,000 simulations for every NFL match,
					providing NFL predictions for every game spanning the regular season and playoffs. These tips are more reliable and precise compared
					to conventional tips seen on alternate websites, as they harness Stats Insider's data-driven analytic model, which simulates every
					game 10,000 times, taking a multitude of variables into the equation, as well as the latest
					<a href="/news?sport=nfl" target="_blank">NFL news</a>, including team form and injury information.</p>

					<h2>Why Opt for Our NFL Betting Tips?</h2>

					<p>Stats Insider's top-tier <b>NFL expert betting tips</b> distinguish themselves due to our data-grounded approach. Employing a
					gamut of data analysis tools and techniques - including statistical analysis, form assessment, and performance scrutiny - we
					deliver the most exact and trustworthy tips for each game. Through our NFL predictive model, we simulate each game 10,000 times,
					ensuring every potential variable is taken into account. Our <a href="/sport-hub/nfl/tipping" target="_blank">NFL tips</a> are
					tailored to facilitate informed betting decisions, rooted in the latest data and insights.</p>
				`
				faqs = [
					{
						headerText: "What makes Stats Insider's NFL Predictions the Best?",
						bodyHTML: "The dependability of our NFL predictions at Stats Insider stems from our data-rooted methodology. We harness an advanced computer modeling algorithm that subjects each NFL game to 10,000 simulations, considering aspects like team form, player injuries, and home-field advantage. This exhaustive analysis guarantees the accuracy and well-informed nature of our predictions."
					},
					{
						headerText: "How Do Stats Insider's NFL Predictions Differ from Conventional Tips?",
						bodyHTML: "Stats Insider's NFL predictions set themselves apart by arising from our autonomous analytic model. This model runs over 10,000 simulations for every NFL match, ensuring a higher level of reliability and precision in comparison to standard tips found on alternative platforms. By eliminating subjective viewpoints and integrating extensive data analysis, our predictions offer a more informed foundation for your betting choices."
					},
					{
						headerText: "Why should I choose to use Stats Insider's NFL tips?",
						bodyHTML: "Opting for Stats Insider's NFL betting tips is astute due to our all-encompassing data-focused approach. We leverage a spectrum of data analysis tools – statistical, form-based, and performance-oriented – to furnish the most accurate and trustworthy predictions for each NFL game. Our distinctive NFL predictive model simulates each game 10,000 times, accounting for all potential elements and furnishing you with the latest insights and data for crafting well-informed betting selections."
					},
				];
				break;
			case "AFLW":
				title = "AFLW Match Predictions | AFLW Games 2022 | Fixtures"
				description = "AFLW predictions for every match. Who will win? Get the latest AFL Women's projections from the home of advanced sporting analytics and machine-learning model predictions."
				// metaImageURL = "https://cdn.ciphersports.io/images/aflw_hub_meta.jpg";
				metaImageURL = "https://cdn.ciphersports.io/images/default_social.jpg";
				// page_description = ;
				// subheading_description = ;
				break;
			case "NBA":
				title = "NBA 2023-24 Match Predictions | Games | Fixtures"
				description = "Welcome to Stats Insider's NBA predictions. Using our advanced computer modeling algorithm, our data-driven predictions are based on thousands of simulations of every NBA game to provide the best NBA bets. Whether you are a first time bettor or a seasoned punter, Stats Insider's NBA predictions are for you."
				// metaImageURL = "https://cdn.ciphersports.io/images/nba_hub_meta.jpg"
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg"
				page_description = "Welcome to Stats Insider's NBA predictions. Using our advanced computer modeling algorithm, our data-driven predictions are based on thousands of simulations of every NBA game to provide the best NBA bets. Whether you are a first time bettor or a seasoned punter, Stats Insider's NBA predictions are for you.";
				subheading_description = `
					<h2>NBA predictions for every game</h2>

					<p>Looking for reliable <b>NBA predictions</b> for every game in 2023? Look no further than Stats
					Insider, your go-to source for data-driven analysis and insights into the NBA.</p>
					
					<p>Using the power of machine learning, our NBA model uses advanced analysis and predictive modeling
					techniques to deliver accurate and reliable <b>NBA predictions for every game</b>. Whether you're a
					seasoned bettor or a casual fan looking to get in on the action, our insights can help you make
					informed bets and stay ahead of the game.</p>
					
					<p>At Stats Insider, we understand that NBA betting can be a complex and ever-changing landscape,
					with a multitude of factors influencing the outcome of each game. That's why we take the human
					element out of the equation and use the cold hard facts.</p>

					<p>From team performance data to player stats and injury updates, we leave no stone unturned in
					our quest to deliver the most comprehensive and accurate predictions and NBA tips possible. Our
					data scientists keep up-to-date with the latest developments in the league,
					<a href="/news?sport=nba" target="_blank">NBA news</a> and everything in between so our NBA predictive model
					has every possible piece of data at its disposal.</p>
					
					<p>So if you're looking for the best NBA bets advice and predictions for every game, look
					no further than Stats Insider.</p>

					<h2>How we make our NBA predictions</h2>

					<p>At Stats Insider, our <b>NBA tips</b> are the result of 10,000 simulations of every game.
					Our NBA predictive model takes into account a wide range of factors such as team form, player
					injuries, home court advantage, and other relevant variables to ensure our NBA predictions
					are reliable and informed. While other websites offer NBA tips based on opinion, our NBA
					predictions are objective and informed by data.</p>

					<h2>What are NBA tips?</h2>

					<p><a href="/sport-hub/nba/tipping" target="_blank">NBA tips</a> are predictions made by our
					predictive model. <b>Our model simulates every NBA game 10,000 times</b> in order to deliver
					NBA predictions on every game throughout the regular season and the playoffs. These tips are
					more reliable and accurate than regular tips you will see on other websites because they
					utilise Stats Insider's independent analytic model, which uses computer learning and is
					driven by data.</p>

					<h2>Why choose our NBA betting tips?</h2>

					<p>Stats Insider's <b>NBA expert betting tips</b> are the best on the market because of
					our data-driven approach. We use a variety of data analysis tools and techniques, including
					statistical analysis, form analysis, and performance analysis to provide the most accurate
					and reliable tips for every game. Using our NBA predictive model, we simulate every game
					10,000 times to take every possible factor into account. Our NBA tips are designed to help
					you make informed betting decisions based on the latest data and insights.</p>
				`;
				faqs = [
					{
						headerText: "What makes Stats Insider's NBA predictions reliable?",
						bodyHTML: "Our NBA predictions at Stats Insider are highly reliable due to our data-driven approach. We employ an advanced computer modeling algorithm that simulates every NBA game 10,000 times, considering factors like team form, player injuries, and home court advantage. This comprehensive analysis ensures that our predictions are accurate and well-informed."
					},
					{
						headerText: "How are Stats Insider's NBA predictions different from regular tips?",
						bodyHTML: "Stats Insider's NBA predictions stand out from regular tips because they are derived from our independent analytic model. This model simulates every NBA game over 10,000 times, offering a higher level of reliability and accuracy compared to standard tips found on other websites. By eliminating subjective opinions and incorporating vast data analysis, our predictions provide a more informed basis for your betting decisions."
					},
					{
						headerText: "Why should I choose Stats Insider's NBA betting tips over others?",
						bodyHTML: "Choosing Stats Insider's NBA betting tips is a smart decision due to our comprehensive data-driven approach. We utilise various data analysis tools, including statistical, form, and performance analysis, to offer the most accurate and reliable predictions for every NBA game."
					},
				]
				break;
			case "SRU":
				title = "Super Rugby 2020 Match Predictions | Games | Fixtures"
				description = "Model predictions for every Super Rugby match. Who will win? Get the latest probabilities from the home of advanced sporting analytics and machine-learning model predictions."
				// metaImageURL = "https://cdn.ciphersports.io/images/sru_hub_meta.jpg";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				// page_description = ;
				// subheading_description = ;
				break;
			case "MLB":
				title = "MLB 2024 Match Predictions | Baseball Games | Schedule"
				description = "Whether you're an experienced MLB bettor or just starting out, Stats Insider can help you make better bets using the power of machine learning. Stats Insider simulates every MLB game 10,000 times to provide MLB predictions and MLB Best Bets."
				// metaImageURL = "https://cdn.ciphersports.io/images/mlb_hub_meta.jpg"
				metaImageURL = "https://cdn.ciphersports.io/images/generic_hub_meta.jpg"
				page_description = "Whether you're an experienced MLB bettor or just starting out, Stats Insider can help you make better bets using the power of machine learning. Stats Insider simulates every MLB game 10,000 times to provide MLB predictions and MLB Best Bets.";
				subheading_description = `
					<h2>MLB Predictions for Every Game in 2024</h2>

					<p>Looking for reliable MLB predictions for every game in 2024? Look no further than Stats Insider,
					your go-to source for objective analysis and insights into Major League Baseball.</p>
					
					<p>Using the power of machine learning, Stats Insider uses advanced data analysis and predictive modeling
					techniques to deliver accurate and reliable MLB Predictions  for every game of the season. Whether you're
					a seasoned bettor or a casual fan looking to get in on the action, our insights can help you make informed
					bets and stay ahead of the game.</p>
					
					<p>At Stats Insider, we understand that MLB betting can be a complex and ever-changing landscape, with a
					multitude of factors influencing the outcome of each game. That’s why we take opinion out of it and deliver
					objective, cutting-edge data analysis techniques to deliver <a href="/best-bets/mlb" target="_blank">MLB
					best bets</a> and tips that are both accurate and reliable.</p>
					
					<h2>How MLB Predictions Work</h2>
					
					<p>From team performance data to player stats and injury updates, we leave no stone unturned in our quest
					to deliver the most comprehensive and accurate MLB predictions and tips possible, as well as offering
					winning probabilities with <a href="/sport-hub/mlb/futures" target="_blank">MLB futures</a>. We use state-of-the-art
					computer learning techniques to ensure we deliver the best possible MLB predictions, which in turn helps give you
					an advantage with your MLB tips.</p>
					
					<p>So if you're looking for the best MLB tipping advice and predictions for every game in 2024, look no further
					than Stats Insider. With our proven track record of success based on objective, data-driven machine learning,
					we can help you stay ahead of the game and make smart, informed bets that give you the best chance of winning.</p>
					
					<h2>How We Make Our MLB Predictions</h2>
					
					<p>At Stats Insider, our MLB betting tips are produced through objective, data-driven analysis using a
					rigorous methodology to provide the most accurate MLB predictions for every game. We take into account
					a wide range of factors such as team form, pitching matchups, <a href="/news?sport=mlb" target="_blank">MLB news</a>,
					home field advantage, and other relevant variables. We then simulate every match-up 10,000 times to give you
					the best possible chance of making informed betting decisions.</p>
				
				`;
				faqs = [
					{
						headerText: "Why are your MLB predictions better than MLB expert tips?",
						bodyHTML: "Most MLB expert tips are made by people. Our MLB tips, on the other hand, are made with machine learning. While MLB expert tips rely on opinion and can be a product of in-built bias, our objective, data-driven tips are derived from science. We take feelings out of the equation and only deal in numbers. These tips are more reliable and accurate than regular tips because they utilise Stats Insider's independent predictive analytics model, which simulates every game 10,000 times."
					},
					{
						headerText: "Why choose our MLB betting tips?",
						bodyHTML: "Stats Insider's MLB betting tips are the best in the market because we utilise the power of the machine to deliver objective, data-driven MLB tips. We use a variety of data analysis tools and techniques, including statistical analysis, pitching analysis, and performance analysis, to provide the most accurate and reliable tips for every game. Our MLB tips are designed to help you make informed betting decisions based on the latest data and insights."
					},
				];
				break;
			case "CBB":
				title = "College Basketball Match Predictions | Games | Fixtures"
				description = "Model predictions for every College Basketball match. Who will win? Get the latest probabilities from the home of advanced sporting analytics and machine-learning model predictions."
				// metaImageURL = "https://cdn.ciphersports.io/images/cbb_hub_meta.jpg";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				page_description = "We run 10,000 simulations per College Basketball game to predict the most likely outcomes and detect value against the markets. Get College Basketball betting tips here. Click or tap on any game below.";
				subheading_description = `<h2>Free College Basketball Betting Tips</h2>
				<p>Stats Insider has College basketball predictions today for every College Basketball game. Based on 1000s of simulations, our computer analyses the data for each and every match in the College Basketball regular season and playoffs, including March Madness, to provide free College Basketball betting tips for you to bet on and become an expert.</p>
				`;
				break;
			case "NHL":
				title = "National Hockey League Predictions | NHL | Schedule"
				description = "Welcome to Stats Insider's NHL predictions. Harnessing cutting-edge computer modeling algorithms, our data-driven predictions emerge from extensive simulations for every NHL match, delivering top-tier NHL insights. Whether you're a seasoned hockey enthusiast or new to the NHL, use Stats Insider's NHL predictions as your go-to source of NHL tips."
				metaImageURL = "https://cdn.ciphersports.io/images/nhl_hub_meta.png"
				page_description = "Welcome to Stats Insider's NHL predictions. Harnessing cutting-edge computer modeling algorithms, our data-driven predictions emerge from extensive simulations for every NHL match, delivering top-tier NHL insights. Whether you're a seasoned hockey enthusiast or new to the NHL, use Stats Insider's NHL predictions as your go-to source of NHL tips.";
				subheading_description = `
					<h2>NHL Predictions for Every Game</h2>

					<p>In pursuit of dependable <b>NHL predictions</b> for every game? Look no further than Stats Insider, your
					ultimate hub for data-driven analysis and enlightenment on the NHL. Empowered by state-of-the-art machine
					learning, our NHL model employs advanced analytical and predictive techniques to furnish you with precise
					and dependable <b>NHL predictions for each game</b>. Whether you're an experienced bettor or a newcomer
					to hockey wagering, our insights empower you to make shrewder NHL bets.</p>

					<p>At Stats Insider, we fully grasp the intricate and ever-evolving nature of <a href="/best-bets/nhl"
					target="_blank">NHL bets</a>, where myriad factors influence the game's outcome. This is why we commit
					to objective analysis, eliminating subjective biases. From team performance metrics to player statistics
					and injury updates, no detail escapes our scrutiny as we strive to offer the most exhaustive, accurate NHL
					predictions, and optimal betting advice. Our team of data scientists remains vigilant, staying abreast of
					the latest league developments, injury reports, and everything in between to ensure our NHL predictive
					model is armed with the most current data. When it comes to obtaining optimal NHL betting guidance and
					game predictions, Stats Insider is your go-to source.</p>

					<h2>Our NHL Predictions Process</h2>

					<p>At Stats Insider, our <b>NHL predictions result from 10,000 simulations of each game</b> carried out by
					our NHL predictive model. This model incorporates an array of variables, including team form, player
					injuries, home-ice advantage, and other relevant factors, ensuring that our predictions for upcoming
					games and <a href="/sport-hub/nhl/futures" target="_blank">NHL futures</a> are both reliable and
					well-informed. While other platforms may offer NHL tips based on subjective opinions, our NHL
					predictions are entirely objective, free from emotional influence.</p>

					<h2>Understanding NHL Tips</h2>

					<p><b><a href="/best-bets/nhl" target="_blank">NHL tips</a></b> are the outcome of our predictive
					model's calculations. Our model conducts 10,000 simulations for each NHL matchup, providing NHL
					predictions for every game throughout the regular season and playoffs. These insights are more
					dependable and precise than conventional tips found on other websites, as they leverage Stats
					Insider's data-driven analytical model. This model simulates every game 10,000 times, factoring
					in a multitude of variables, along with the latest NHL news, including team form and injury
					information.</p>

					<h2>Why Prefer Stats Insider's NHL Betting Insights?</h2>

					<p>Stats Insider's premier <b>NHL expert betting tips</b> stand out due to our data-driven methodology.
					Employing a wide range of data analysis tools and techniques, including statistical analysis, form
					evaluation, and performance assessment, we deliver the most accurate and reliable insights for each
					game. Through our NHL predictive model, we simulate each game 10,000 times, ensuring that every
					potential variable is thoroughly considered. Our NHL insights are tailored to facilitate well-informed
					betting decisions, rooted in the latest data and wisdom.</p>
				`;
				faqs = [
					{
						headerText: "Why Are Stats Insider's NHL Predictions The Best?",
						bodyHTML: "The credibility of our NHL predictions at Stats Insider arises from our data-driven approach. We employ an advanced computer modeling algorithm that subjects each NHL game to 10,000 simulations, accounting for factors such as team form, player injuries, and home-ice advantage. This thorough analysis ensures the accuracy and well-informed nature of our predictions."
					},
					{
						headerText: "How Do Stats Insider's NHL Predictions Differ from Traditional Tips?",
						bodyHTML: "Stats Insider's NHL predictions stand out because they are generated by our impartial analytical model. This model runs over 10,000 simulations for every NHL match, ensuring a higher level of reliability and precision compared to standard tips found on alternative platforms. By removing subjective opinions and integrating extensive data analysis, our predictions offer a more informed basis for your betting choices."
					},
					{
						headerText: "Why Should I Use Stats Insider's NHL Tips?",
						bodyHTML: "Choosing Stats Insider's NHL betting insights is a wise decision due to our comprehensive data-focused approach. We leverage a spectrum of data analysis tools – statistical, form-based, and performance-oriented – to provide the most accurate and reliable predictions for each NHL game. Our unique NHL predictive model simulates each game 10,000 times, accounting for all potential variables, and equips you with the latest insights and data to make well-informed betting decisions."
					},
				];
				break;
			case "EURO":
				title = "Euro 2020 Predictions | European Championship | Fixtures";
				description = "Model predictions for every Euro 2020 match. Who will win? Get the latest probabilities from the home of advanced sporting analytics and machine-learning model predictions.";
				metaImageURL = "https://cdn.ciphersports.io/images/euro_hub_meta.jpg";
				// page_description = ;
				// subheading_description = ;
				break;
			case "SWC":
				title = "World Cup 2022 Predictions | FIFA World Cup | Fixtures";
				description = "Model predictions for every 2022 FIFA World Cup match. Who will win? Get the latest probabilities from the home of advanced sporting analytics and machine-learning model predictions.";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				page_description = "We run 10,000 simulations per World Cup soccer game to predict the most likely outcomes and detect value against the markets. Get World Cup 2022 betting tips here. Click or tap on any game below.";
				subheading_description = `<h2>Free World Cup 2022 Betting Tips</h2>
				<p>Stats Insider has College Football predictions this week for every World Cup 2022 game. Based on 1000s of simulations, our computer analyses the data for each and every match in the 2022 World Cup in Qatar, including the World Cup Final, to provide free soccer betting tips for you to bet on and become an expert.</p>
				`;
				break;
			case "MLS":
				title = "MLS Predictions | Major League Soccer | Fixtures";
				description = "Model predictions for every MLS match. Who will win? Get the latest probabilities from the home of advanced sporting analytics and machine-learning model predictions."
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				page_description = "We run 10,000 simulations per MLS game to predict the most likely outcomes and detect value against the markets. Get MLS betting tips here. Click or tap on any game below.";
				subheading_description = `<h2>Free MLS Betting Tips</h2>
				<p>Stats Insider has MLS predictions this week for every MLS game. Based on 1000s of simulations, our computer analyses the data for each and every match in the MLS regular season and playoffs, including the MLS Cup, to provide free MLS betting tips for you to bet on and become an expert.</p>
				`;
			default:
				title = "The Home of Sports Predictions";
				description = "Visit Australia's leading sports analytics platform for the fans. Stats Insider simulates every match over 10,000 times to give you unprecedented insights into the sports you love.";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				// page_description = ;
				// subheading_description = ;
				break;
			
		}

		return of({
			site_title: title,
			seo_scheduled_title: title,
			seo_scheduled_description: description,
			thumbnail: {url: metaImageURL},
			page_description: page_description,
			subheading_description: subheading_description,
			faqs: faqs,
		})
	}

	getMatchMeta(matchID: string): Observable<Record<string, any>> {
		console.log('getMatchMeta');
		let rno = Math.random()
		console.time('getMatchMeta' + rno)
		return this.http.get<any>(`${environment.apiDomain}/pre/${matchID}`)
		.pipe(
			map((match: Match) => {
				console.timeLog('getMatchMeta' + rno)
				let title: string, description: string, match_subheading_title: string, match_subheader: string, match_subheading_description: string;
				let faqs: Array<{headerText: string, bodyHTML: string}> = [];
				const matchName = match.MatchData.Sport === "TEN" ?
					`${match.MatchData.PlayerData.player1.nameDetails.first} ${match.MatchData.PlayerData.player1.nameDetails.last} vs. ${match.MatchData.PlayerData.player2.nameDetails.first} ${match.MatchData.PlayerData.player2.nameDetails.last}`
					: `${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} vs. ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}`
				
				if (match.MatchData.Sport === "TEN") {
					title = `${match.MatchData.PlayerData.player1.nameDetails.first} ${match.MatchData.PlayerData.player1.nameDetails.last} `
						+ `vs ${match.MatchData.PlayerData.player2.nameDetails.first} ${match.MatchData.PlayerData.player2.nameDetails.last} - `
						+ `${match.MatchData.TournamentName} Tennis Match Predictions`;

					description = `Match predictions: ${this.localisationService.localeTerm('h2h_short', '')}, set and game probabilities for ${match.MatchData.TournamentName} `
						+ `${match.MatchData.PlayerData.player1.nameDetails.first} ${match.MatchData.PlayerData.player1.nameDetails.last} ` 
						+ `vs ${match.MatchData.PlayerData.player2.nameDetails.first} ${match.MatchData.PlayerData.player2.nameDetails.last}.`;
				} else if (match.MatchData.Sport === "SOO") {
					title = `${match.MatchData.HomeTeam.DisplayName} vs ${match.MatchData.AwayTeam.DisplayName} State of Origin Game ${match.MatchData.RoundNumber} ${match.MatchData.Season}`;
					description = `It's Queensland vs New South Wales in rugby league's most famous rivalry. See ${this.localisationService.localeTerm('h2h_short', '')}, `
						+ `${this.localisationService.localeTerm('line', '')} and ${this.localisationService.localeTerm('total', '')} probabilities for `
						+ `Game ${match.MatchData.RoundNumber}.`;
					match_subheader = `Stats Insider's State of Origin predictions for the game between ${match.MatchData.HomeTeam.Abv} and ${match.MatchData.AwayTeam.Abv} on ${format(parseJSON(match.MatchData.Date), "iiii, MMMM d, yyyy")}, are featured below, including our best bets, try scorer probabilities, and the latest odds.`;
				} else if (["AFL","AFLW"].includes(match.MatchData.Sport)) {
					title = `${match.MatchData.HomeTeam.DisplayName} vs ${match.MatchData.AwayTeam.DisplayName}`
						+ ` - ${match.MatchData.Season} Round ${match.MatchData.RoundNumber} ${match.MatchData.Sport} Match Predictions`;

					description = `Match predictions: ${this.localisationService.localeTerm('h2h_short', '')}, ${this.localisationService.localeTerm('line', '')} and ${this.localisationService.localeTerm('total', '')} probabilities for ${match.MatchData.Season} `
						+ `${match.MatchData.Sport} Round ${match.MatchData.RoundNumber} `
						+ `${match.MatchData.HomeTeam.DisplayName} vs ${match.MatchData.AwayTeam.DisplayName}.`
					match_subheader = `After simulating ${matchName} on ${format(parseJSON(match.MatchData.Date), "iii MMM d, yyyy")} 10,000 times, Stats Insider's <strong>predictions</strong> and <strong>picks</strong>, including <a routerLink='/best-bets'>best bets</a>, <strong>betting odds</strong> and <a routerLink='/live-now'>live updates</a> can be found below.`;
				} else if (["NRL"].includes(match.MatchData.Sport)) {
					title = `${match.MatchData.HomeTeam.DisplayName} vs ${match.MatchData.AwayTeam.DisplayName}`
						+ ` - ${match.MatchData.Season} Round ${match.MatchData.RoundNumber} ${match.MatchData.Sport} Match Predictions`;

					description = `Match predictions: ${this.localisationService.localeTerm('h2h_short', '')}, ${this.localisationService.localeTerm('line', '')} and ${this.localisationService.localeTerm('total', '')} probabilities for ${match.MatchData.Season} `
						+ `${match.MatchData.Sport} Round ${match.MatchData.RoundNumber} `
						+ `${match.MatchData.HomeTeam.DisplayName} vs ${match.MatchData.AwayTeam.DisplayName}.`
					match_subheader = `Stats Insider has simulated ${matchName} on ${format(parseJSON(match.MatchData.Date), "iii MMM d, yyyy")} 10,000 times to provide <strong>predictions</strong> and <strong>picks</strong>, including <a routerLink='/best-bets'>best bets</a>, <strong>betting odds</strong> and <a routerLink='/live-now'>live updates</a>.`;
				} else if (match.MatchData.Sport === "BBL") {
					title = `${match.MatchData.HomeTeam.DisplayName} vs ${match.MatchData.AwayTeam.DisplayName} - BBL13 Match Predictions`;
					description = `Match predictions and live win probabilities for ${match.MatchData.Sport} `
						+ `${match.MatchData.HomeTeam.DisplayName} vs ${match.MatchData.AwayTeam.DisplayName}.`
				} else {
					title = `${match.MatchData.HomeTeam.DisplayName} vs ${match.MatchData.AwayTeam.DisplayName} `
						+ `- ${match.MatchData.Sport === "ESP" ? "La Liga" : (match.MatchData.Sport === "ALG" ? "A-League" : match.MatchData.Sport)} Match Predictions`;

					if (["NFL", "CFB"].includes(match.MatchData.Sport)) {
						description = `Match predictions: ${this.localisationService.localeTerm('h2h_short', '')}, ${this.localisationService.localeTerm('line', '')} and ${this.localisationService.localeTerm('total', '')} probabilities for ${match.MatchData.Season} `
							+ `${match.MatchData.Sport === "CFB" ? "College Football" : match.MatchData.Sport} Week ${match.MatchData.RoundNumber} `
							+ `${match.MatchData.HomeTeam.DisplayName} vs ${match.MatchData.AwayTeam.DisplayName}.`
					} else if (match.MatchData.Sport === "MLB") {
						description = `Match predictions: ${this.localisationService.localeTerm('h2h_short', '')}, ${this.localisationService.localeTerm('line_mlb', '')} and ${this.localisationService.localeTerm('total', '')} probabilities for MLB `
							+ `${match.MatchData.HomeTeam.DisplayName} vs ${match.MatchData.AwayTeam.DisplayName}.`;
					} else if (["EPL", "ESP", "ALG"].includes(match.MatchData.Sport)) {
						description = `Match predictions: Result, correct score and ${this.localisationService.localeTerm('total', '')} probabilities for `
							+ `${match.MatchData.Sport === "ESP" ? "La Liga" : (match.MatchData.Sport === "ALG" ? "A-League" : match.MatchData.Sport)} `
							+ `matchweek ${match.MatchData.RoundNumber} ${match.MatchData.HomeTeam.DisplayName} vs ${match.MatchData.AwayTeam.DisplayName}.`;
					} else if (match.MatchData.Sport === "NBA") {
						description = `Match predictions: Result, ${this.localisationService.localeTerm('line', '')} and ${this.localisationService.localeTerm('total', '')} probabilities for NBA gameday ${match.MatchData.RoundNumber} `
							+ `${match.MatchData.HomeTeam.DisplayName} vs ${match.MatchData.AwayTeam.DisplayName}.`;
					} else {
						description = `Match predictions: ${this.localisationService.localeTerm('h2h_short', '')}, ${this.localisationService.localeTerm('line', '')} and ${this.localisationService.localeTerm('total', '')} probabilities for ${match.MatchData.Sport} `
							+ `${match.MatchData.HomeTeam.DisplayName} vs ${match.MatchData.AwayTeam.DisplayName}.`;
					}
				}

				
				return {
					title: title,
					match_id: match.MatchData.SIMatchID,
					match_header: match.MatchData.Sport === "SOO" ? `${matchName} Game ${match.MatchData.RoundNumber}` : `${matchName} Predictions`,
					match_subheading_title: match_subheading_title || null,
					match_subheader: match_subheader || `<strong>Predictions</strong> and <strong>picks</strong> for ${matchName} on ${format(parseJSON(match.MatchData.Date), "iii MMM d, yyyy")}, including <a routerLink='/best-bets'>best bets</a>, <strong>betting odds</strong> and <a routerLink='/live-now'>live updates</a>.`,
					match_subheading_description: match_subheading_description || null,
					default_description: description,
					home_team_name: match.MatchData.Sport === "TEN" ? `${match.MatchData.PlayerData.player1.nameDetails.first} ${match.MatchData.PlayerData.player1.nameDetails.last}` : match.MatchData.HomeTeam.DisplayName,
					visiting_team_name: match.MatchData.Sport === "TEN" ? `${match.MatchData.PlayerData.player2.nameDetails.first} ${match.MatchData.PlayerData.player2.nameDetails.last}` : match.MatchData.AwayTeam.DisplayName,
					match_date: match.MatchData.Date,
					venue: match.MatchData.Sport === "TEN" ? match.MatchData.TournamentName : match.MatchData.Venue,
					description: description,
					// thumbnail: {url: "https://cdn.ciphersports.io/images/generic_match_page_meta.jpg"},
					thumbnail: {url: environment.defaultMetaImage},
					faqs: faqs,
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	// TODO
	getBestOddsMeta(): Observable<Array<Record<string, any>>> {
		console.log('getBestOddsMeta');
		return of([]);
	}

	getNewsMeta(sportCode: string): Observable<Record<string, any>> {
		if (sportCode === "All") {
			return of({
				page_title: `Latest Sports Betting News`,
				seo_title: `Articles`,
				seo_description: `Empowering Australian sport. Our vision is to evolve the sports fan experience by making data-driven predictive content widely available to sports nuts like us.`,
				page_description: `Stats Insider provides you with the latest in sports betting news, tips and more, as well as detailed information pieces about players and teams from across the world.`,
				subheading_description: `<h2>Sports Betting News and Opinion</h2>
				<p>As well as our famous match predictions and futures projections, Stats Insider provides you with sports betting news in the form of betting tips and statistical analysis to give you the tools you need to succeed in betting on AFL, NRL, NBA, EPL and more.</p>
				`,
			})
		}
		const sport = SPORTS.find(s => s.code === sportCode.toUpperCase());
		if (sport) {
			switch (sport.code) {
				case "AFL":
					return of({
						page_title: `Latest AFL News for Season 2024`,
						seo_title: `Latest AFL News for Season 2024`,
						seo_description: `Stay up-to-date with the latest AFL news. Our comprehensive coverage includes in-depth analysis and exclusive insights on all AFL teams and players.`,
						page_description: `Stats Insider provides you with the latest in AFL betting news, tips and more,
							as well as detailed information pieces about players and teams from across the competition.`,
						subheading_description: `<h2>AFL Betting News and Opinion Articles</h2>
						<p>As well as our famous AFL match predictions and futures projections, Stats Insider provides you
							with AFL news in the form of betting tips and statistical analysis to give you the tools you need
							to succeed in AFL betting, AFL fantasy and more.</p>`,
					})
				case "NRL":
					return of({
						page_title: `Latest NRL News for Season 2024`,
						seo_title: `Latest NRL News for Season 2024`,
						seo_description: `Stay up-to-date with the latest NRL news. Our comprehensive coverage includes in-depth analysis and exclusive insights on all NRL teams and players.`,
						page_description: `Stats Insider provides you with the latest in NRL betting news, tips and more,
							as well as detailed information pieces about players and teams from across the competition.`,
						subheading_description: `<h2>NRL News and Opinion</h2>
						<p>As well as our famous NRL match predictions and futures projections, Stats Insider provides you
							with NRL news in the form of betting tips and statistical analysis to give you the tools you need
							to succeed in NRL betting, NRL fantasy and more.</p>`,
					})
				case "NFL":
					return of({
						page_title: `Latest NFL News`,
						seo_title: `Latest NFL News`,
						seo_description: `The latest NFL news and analysis from Stats Insider.`,
						page_description: `Stats Insider provides you with the latest in NFL betting news, tips and more,
							as well as detailed information pieces about players and teams from across the competition.`,
						subheading_description: `<h2>NFL News and Opinion</h2>
						<p>As well as our famous NFL match predictions and futures projections, Stats Insider provides you
							with NFL news in the form of betting tips and statistical analysis to give you the tools you need
							to succeed in NFL betting, NFL fantasy and more.</p>`,
					})
				case "MLB":
					return of({
						page_title: `Latest MLB News`,
						seo_title: `Latest MLB News`,
						seo_description: `The latest MLB news and analysis from Stats Insider.`,
						page_description: `Stats Insider provides you with the latest in MLB betting news, tips and more,
							as well as detailed information pieces about players and teams from across the competition.`,
						subheading_description: `<h2>MLB News and Opinion</h2>
						<p>As well as our famous MLB match predictions and futures projections, Stats Insider provides you
							with MLB news in the form of betting tips and statistical analysis to give you the tools you need
							to succeed in MLB betting, MLB fantasy and more.</p>`,
					})
				case "CFB":
					return of({
						page_title: `Latest College Football News`,
						seo_title: `Latest College Football News`,
						seo_description: `The latest College Football news and analysis from Stats Insider.`,
						page_description: `Stats Insider provides you with the latest in College Football betting news, tips
							and more, as well as detailed information pieces about players and teams from across the competition.`,
						subheading_description: `<h2>College Football News and Opinion</h2>
						<p>As well as our famous College Football match predictions and futures projections, Stats Insider provides you with College
							Football news in the form of betting tips and statistical analysis to give you the tools you need
							to succeed in NCAAF betting and more.</p>`,
					})
				case "EPL":
					return of({
						page_title: `Latest EPL News`,
						seo_title: `Latest EPL News`,
						seo_description: `The latest EPL news and analysis from Stats Insider.`,
						page_description: `Stats Insider provides you with the latest in EPL betting news, tips and more,
							as well as detailed information pieces about players and teams from across the competition.`,
						subheading_description: `<h2>EPL News and Opinion</h2>
						<p>As well as our famous EPL match predictions and futures projections, Stats Insider provides you with EPL
							news in the form of betting tips and statistical analysis to give you the tools you need to succeed in
							EPL betting, Fantasy Premier League and more.</p>`,
					})
				case "SWC":
					return of({
						page_title: `Latest World Cup 2022 News`,
						seo_title: `Latest World Cup 2022 News`,
						seo_description: `The latest World Cup 2022 news and analysis from Stats Insider.`,
						page_description: `Stats Insider provides you with the latest in World Cup 2022 betting news,
							tips and more, as well as detailed information pieces about players and teams from across the competition.`,
						subheading_description: `<h2>World Cup 2022 News and Opinion</h2>
						<p>As well as our famous World Cup match predictions and futures projections, Stats Insider provides you with
							World Cup 2022 news in the form of betting tips and statistical analysis to give you the tools you need
							to succeed in Qatar World Cup betting and more.</p>`,
					})
				case "GOLF":
					return of({
						page_title: `Latest Golf News`,
						seo_title: `Latest Golf News`,
						seo_description: `The latest Golf news and analysis from Stats Insider.`,
						page_description: `Stats Insider provides you with the latest in golf betting news, tips and more,
							as well as detailed information pieces about players from across the PGA.`,
						subheading_description: `<h2>Golf News and Opinion</h2>
						<p>As well as our famous PGA tournament predictions and world golf rankings, Stats Insider provides you with
							golf news in the form of betting tips and statistical analysis to give you the tools you need to succeed
							in golf betting, PGA daily fantasy and more.`,
					})
				case "ESP":
					return of({
						page_title: `Latest La Liga News`,
						seo_title: `Latest La Liga News`,
						seo_description: `The latest La Liga news and analysis from Stats Insider.`,
						page_description: `Stats Insider provides you with the latest in La Liga betting news, tips and more,
							as well as detailed information pieces about players and teams from across the competition.`,
						subheading_description: `<h2>La Liga News and Opinion</h2>
						<p>As well as our famous La Liga match predictions and futures projections, Stats Insider provides you with
							La Liga news in the form of betting tips and statistical analysis to give you the tools you need to succeed
							in La Liga betting and more.</p>`,
					})
				case "MLS":
					return of({
						page_title: `Latest MLS News`,
						seo_title: `Latest MLS News`,
						seo_description: `The latest MLS news and analysis from Stats Insider.`,
						page_description: `Stats Insider provides you with the latest in MLS betting news, tips and more,
							as well as detailed information pieces about players and teams from across the competition.`,
						subheading_description: `<h2>MLS News and Opinion</h2>
						<p>As well as our famous MLS match predictions and futures projections, Stats Insider provides you with
							MLS news in the form of betting tips and statistical analysis to give you the tools you need to succeed
							in MLS betting, MLS fantasy and more.</p>
						`,
					})
				case "BBL":
					return of({
						page_title: `Latest Big Bash News`,
						seo_title: `Latest Big Bash News`,
						seo_description: `The latest Big Bash news and analysis from Stats Insider.`,
						page_description: `Stats Insider provides you with the latest in BBL betting news, tips and more,
							as well as detailed information pieces about players and teams from across the competition.`,
						subheading_description: `<h2>Big Bash News and Opinion</h2>
						<p>As well as our famous BBL match predictions and futures projections, Stats Insider provides you with
							Big Bash news in the form of betting tips and statistical analysis to give you the tools you need to
							succeed in Big Bash betting, Big Bash fantasy and more.</p>`,
					})
				case "NBA":
					return of({
						page_title: `Latest NBA News`,
						seo_title: `Latest NBA News`,
						seo_description: `The latest NBA news and analysis from Stats Insider.`,
						page_description: `Stats Insider provides you with the latest in NBA betting news, tips and more,
							as well as detailed information pieces about players and teams from across the competition.`,
						subheading_description: `<h2>NBA News and Opinion</h2>
						<p>As well as our famous NBA match predictions and futures projections, Stats Insider provides you with
							basketball news in the form of betting tips and statistical analysis to give you the tools you need
							to succeed in NBA betting, NBA fantasy and more.</p>`,
					})
				case "NHL":
					return of({
						page_title: `Latest NHL News`,
						seo_title: `Latest NHL News`,
						seo_description: `The latest NHL news and analysis from Stats Insider.`,
						page_description: `Stats Insider provides you with the latest in NHL betting news, tips and more,
							as well as detailed information pieces about players and teams from across the competition.`,
						subheading_description: `<h2>NHL News and Opinion</h2>
						<p>As well as our famous NHL match predictions and futures projections, Stats Insider provides you with
							NHL news in the form of betting tips and statistical analysis to give you the tools you need to
							succeed in NHL betting, NHL fantasy and more.</p>`,
					})
				case "CBB":
					return of({
						page_title: `Latest College Basketball News`,
						seo_title: `Latest College Basketball News`,
						seo_description: `The latest College Basketball news and analysis from Stats Insider.`,
						page_description: `Stats Insider provides you with the latest in College Basketball betting news, tips and more,
							as well as detailed information pieces about players and teams from across the competition.`,
						subheading_description: `<h2>College Basketball News and Opinion</h2>
						<p>As well as our famous College Basketball match predictions and futures projections, Stats Insider provides you
							with College Basketball news in the form of betting tips and statistical analysis to give you the tools you
							need to succeed in NCAAB betting and more.</p>`,
					})
				case "TEN":
					return of({
						page_title: `Latest Tennis News`,
						seo_title: `Latest Tennis News`,
						seo_description: `The latest Tennis news and analysis from Stats Insider.`,
						page_description: `Stats Insider provides you with the latest in tennis betting news, tips and more,
							as well as detailed information pieces about players and tournaments from across both the men's and women's circuits.`,
						subheading_description: `<h2>ATP & WTA News and Opinion</h2>
						<p>As well as our famous tennis match predictions and world rankings, Stats Insider provides you with tennis news in the
							form of betting tips and statistical analysis to give you the tools you need to succeed when betting on the Australian
							Open, Wimbledon, Roland Garros, US Open and more.</p>`,
					})
				case "RACING":
					return of({
						page_title: `Horse Racing Tips`,
						seo_title: `Horse Racing Tips`,
						seo_description: `Stats Insider's free horse racing tips are your trusted source for data-driven insights that will elevate your horse racing betting game.`,
						page_description: `<p>Stats Insider's free horse racing tips are your trusted source for data-driven insights that will elevate your horse racing betting game.</p>`,
						subheading_description: `<h2>Free Horse Racing Tips Today</h2>
						<p>Your go-to source for free horse racing tips backed by data-driven insights and expert analysis is <a href="https://www.statsinsider.com.au">Stats Insider</a>. With our comprehensive approach, you'll gain a competitive edge in the world of horse racing.</p>
						<h2>Why Trust Stats Insider?</h2>
						<p>At Stats Insider, we pride ourselves on delivering high-quality racing tips that are not only accurate but also free of charge. Here's why you should consider our insights as your trusted resource:</p>
						<h3>Data-Driven Insights</h3>
						<p>Our core philosophy revolves around the power of data. We employ advanced statistical models and cutting-edge algorithms to analyse a vast array of racing-related information. From historical performance data to jockey and trainer statistics, we leave no stone unturned to ensure our predictions are grounded in concrete data.</p>
						<h3>Objective Analysis</h3>
						<p>Bias has no place in our predictions. Our data-driven approach ensures that all tips are generated without any emotional influence. We let the numbers do the talking, providing you with an objective perspective on race outcomes.</p>
						<h3>&nbsp;In-Depth Statistics</h3>
						<p>We provide more than just tips; we offer a deep dive into the statistics that matter. With detailed information on each horse, jockey, and trainer, you'll gain a comprehensive understanding of the factors influencing our predictions.</p>
						<h3>Consistency</h3>
						<p>Consistency is the key to success in horse racing. Stats Insider's methodology remains steadfast over time, making us a dependable source for those who value data-driven insights that stand the test of time.</p>
						<h3>The Benefits of Free Access</h3>
						<p>One of the standout features of Stats Insider's free horse racing tips is that they are accessible to all, free of charge. We believe in providing valuable insights to as many racing enthusiasts as possible, making our tips available to a wide audience.</p>
						<h2>How to Use Stats Insider's Tips</h2>
						<p>Using our free horse racing tips is simple and straightforward. Here's a step-by-step guide to maximize the benefits:</p>
						<h3>Step 1: Access Our Tips</h3>
						<p>Visit our website and navigate to the "Racing" section. There, you'll find our latest predictions for upcoming races.</p>
						<h3>Step 2: Study the Insights</h3>
						<p>Take your time to study the insights we provide for each race. Our tips include information on the horses, jockeys, and trainers, enabling you to make well-informed decisions.</p>
						<h3>Step 3: Formulate Your Strategy</h3>
						<p>With our data-driven insights in hand, you can formulate your betting strategy. Whether you prefer to back the favourites or look for value bets, our tips will assist you in making informed choices.</p>
						<h3>Step 4: Enjoy the Races</h3>
						<p>Tune in to the races and watch the action unfold. With Stats Insider's tips guiding you, you'll have a deeper appreciation of the race dynamics and a more exciting betting experience.</p>
						<h2>Why Stats Insider?</h2>
						<p>While there are various sources of racing tips available, Stats Insider stands out for several reasons:</p>
						<h3>Proven Track Record</h3>
						<p>Our tips have consistently delivered results, helping bettors make profitable decisions.</p>
						<h3>Transparency</h3>
						<p>We believe in transparency. We provide detailed explanations of our methodology and the key factors influencing our tips.</p>
						<h3>Continuous Improvement</h3>
						<p>We are committed to constant improvement. Our team of data analysts and racing experts is always working to enhance our models and provide even more accurate predictions.</p>
						<h2>Horse Racing with Stats Insider</h2>
						<p>Ready to elevate your horse racing betting game? Stats Insider's free horse racing tips are your ticket to success. Join the thousands of users who trust our insights and experience the thrill of making well-informed bets.</p>
						<p>Don't miss out on our latest tips, and start exploring our predictions today. Whether you're a seasoned bettor or just starting, our data-driven insights are your pathway to winning at the races. Get started now and enjoy the excitement of horse racing with Stats Insider by your side.</p>
						<h3>Get in Touch</h3>
						<p>Have questions or need assistance? Feel free to reach out to our <a href="mailto:support@statsinsider.com.au">support team</a>. We're here to help you on your journey to becoming a more informed and successful punter.</p>
						<p>Stay tuned to Stats Insider for the latest tips, news, and updates.</p>`
					})
				default:
					return of({
						page_title: `${sport.shortName} News`,
						seo_title: `${sport.shortName} News`,
						seo_description: `The latest ${sport.shortName} news and analysis from Stats Insider.`,
						page_description: ``,
						subheading_description: ``,
					})
			}
			

		} else {
			return of({
				page_title: `News`,
				seo_title: environment.siteTitle,
				seo_description: environment.defaultMetaDescription,
				page_description: ``,
				subheading_description: ``,
			})
		}
	}

	getFuturesMeta(sportCode: string): Observable<Record<string, any>> {
		console.log('getFuturesMeta');
		let title: string, description: string, metaImageURL: string, page_description: string = null, subheading_description: string = null;
		let faqs: Array<{headerText: string, bodyHTML: string}> = [];
		const sport = this.localisationService.sportPrioritySort(SPORTS).find(s => s.code.toUpperCase() === sportCode.toUpperCase());
		switch (sportCode.toUpperCase()) {
			case "EPL":
				title = "EPL 2024-25 Futures | English Premier League Predictor";
				description = "EPL Title and Top 4 futures probabilities. Where will your Premiership club finish at the end of the year?";
				metaImageURL = "https://cdn.ciphersports.io/images/epl_futures_meta.jpg";
				page_description = "Looking for Premier League futures and current EPL title odds? Stats Insider has simulated the entire EPL season over 10,000 times to help you pick out a winning bet on the English Premier League.";
				subheading_description = `<h2>Free EPL Title Betting Tips</h2>
				<p>Stats Insider has Premier League futures predictions for the current soccer season. Based on 1000s of simulations, our computer analyses the data for each and every match for the entirety of the EPL season to determine the likelihood of each team winning the EPl title, finishing in the top four and getting relegated.</p>
				`;
				break;
			case "EURO":
				title = "Euro 2020 Futures | European Championship Predictor";
				description = "European Football Championship probabilities. How far can your favourite team go in the tournament?";
				metaImageURL = "https://cdn.ciphersports.io/images/euro_futures_meta.jpg";
				// page_description = ;
				// subheading_description = ;
				break;
			case "SWC":
				title = "FIFA World Cup 2022 Futures | World Cup Predictor";
				description = "FIFA World Cup probabilities. How far can your favourite team go in the tournament?";
				// metaImageURL = "https://cdn.ciphersports.io/images/euro_futures_meta.jpg";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				page_description = "Looking for World Cup soccer futures and current 2022 World Cup winner odds? Stats Insider has simulated the entire Qatar World Cup over 10,000 times to help you pick out a winning bet on the 2022 World Cup.";
				subheading_description = `<h2>Free World Cup Winner Betting Tips</h2>
				<p>Stats Insider has World Cup 2022 futures predictions for the current soccer World Cup. Based on 1000s of simulations, our computer analyses the data for each and every match for the entirety of the soccer World Cup to determine the likelihood of each team winning the World Cup, making the final and qualifying from the group stage.</p>
				`;
				break;
			case "ESP":
				title = "La Liga 2024-25 Futures | La Liga Predictor";
				description = "La Liga Title and Top 4 futures probabilities. Where will your Spanish club finish at the end of the year?";
				metaImageURL = "https://cdn.ciphersports.io/images/esp_futures_meta.jpg";
				page_description = "Looking for La Liga futures and current La Liga title odds? Stats Insider has simulated the entire Spanish soccer season over 10,000 times to help you pick out a winning bet on the La Liga title.";
				subheading_description = `<h2>Free La Liga Title Betting Tips</h2>
				<p>Stats Insider has La Liga futures predictions for the current soccer season. Based on 1000s of simulations, our computer analyses the data for each and every match for the entirety of the La Liga season to determine the likelihood of each team winning the La Liga title, finishing in the top four and getting relegated.</p>
				`;
				break;
			case "ALG":
				title = "A-League 2020-21 Premiership Futures | Ladder Predictor";
				description = "A-League Title and Finals futures probabilities. Top 2? Top 6? Or take the Wooden Spoon? Where will your A-League club finish at the end of the year?";
				metaImageURL = "https://cdn.ciphersports.io/images/alg_futures_meta.jpg";
				// page_description = ;
				// subheading_description = ;
				break;
			case "BBL":
				title = "BBL 2023-24 Predictor | BBL Futures";
				description = "BBL Championship futures probabilities. Where will your Big Bash team finish at the end of the season?";
				// metaImageURL = "https://cdn.ciphersports.io/images/bbl_futures_meta.jpg";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				page_description = "Looking for BBL futures and current Big Bash odds? Stats Insider has simulated the entire Big Bash League season over 10,000 times to help you pick out a winning bet on the BBL.";
				subheading_description = `<h2>Free Big Bash Championship Betting Tips</h2>
				<p>Stats Insider has Big Bash futures predictions for the current T20 cricket season. Based on 1000s of simulations, our computer analyses the data for each and every match for the remainder of the BBL season to determine the likelihood of each team winning the Big Bash League.</p>
				`;
				break;
			case "NBA":
				title = "NBA 2023-24 Championship Futures | NBA Predictor";
				description = "NBA Title probabilities. Where will your NBA team finish at the end of the season?";
				// metaImageURL = "https://cdn.ciphersports.io/images/nba_futures_meta.jpg";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				page_description = "Looking for NBA futures and current NBA title odds? Stats Insider has simulated the entire NBA season over 10,000 times to help you pick out a winning bet on the NBA title.";
				subheading_description = `<h2>Free NBA Title Betting Tips</h2>
				<p>Stats Insider has NBA futures predictions for the current basketball season. Based on 1000s of simulations, our computer analyses the data for each and every match for the remainder of the NBA season to determine the likelihood of each team winning the NBA title, winning their conference and winning their division.</p>
				`;
				break;
			case "CBB":
				title = "2023-24: College Basketball Futures";
				description = "2023-24 predictions for all the key markets of the NCAA Men's Basketball Championship, built by the Stats Insider machine learning models.";
				metaImageURL = "https://cdn.ciphersports.io/images/default_social.png";
				page_description = "Looking for College Basketball futures and current March Madness odds? Stats Insider has simulated the entire College Football season over 10,000 times to help you pick out a winning bet on the National Championship.";
				subheading_description = `<h2>Free March Madness Betting Tips</h2>
				<p>Stats Insider has College Football futures predictions for the current football season. Based on 1000s of simulations, our computer analyses the data for each and every match for the remainder of the College Basketball season to determine the likelihood of each team winning March Madness.</p>
				`;
				break;
			case "NFL":
				title = "2023 NFL Super Bowl Futures | NFL Predictor";
				description = "NFL Playoffs and Championship futures probabilities. Division Winners. Conference Champions. Super Bowl Winners? Where will your NFL team finish at the end of the season?";
				metaImageURL = "https://cdn.ciphersports.io/images/nfl_futures_meta.jpg";
				page_description = "Looking for NFL futures and current NFL Super Bowl odds? Stats Insider has simulated the entire NFL season over 10,000 times to help you pick out a winning bet on the Super Bowl.";
				subheading_description = `<h2>Free NFL Super Bowl Betting Tips</h2>
				<p>Stats Insider has NFL futures predictions for the current American football season. Based on 1000s of simulations, our computer analyses the data for each and every match for the entirety of the NFL season to determine the likelihood of each team winning the Super Bowl, winning their conference and winning their division.</p>
				`;
				break;
			case "CFB":
				title = "2023 College Football Futures | CFB Predictor";
				description = "CFB futures probabilities. Division Winners. Conference Champions. National Champions? Where will your team finish at the end of the season?";
				metaImageURL = "https://cdn.ciphersports.io/images/default_social.png";
				page_description = "Looking for College Football futures and current College Football Championship odds? Stats Insider has simulated the entire College Football season over 10,000 times to help you pick out a winning bet on the National Championship.";
				subheading_description = `<h2>Free College Football Championship Betting Tips</h2>
				<p>Stats Insider has College Football futures predictions for the current football season. Based on 1000s of simulations, our computer analyses the data for each and every match for the entirety of the College Football season to determine the likelihood of each team winning the National Championship, winning their conference and winning their division.</p>
				`;
				break;
			case "MLB":
				title = "MLB World Series Futures | 2024 MLB Predictor";
				description = "MLB Playoff and World Series probabilities. Where will your MLB club finish at the end of the year?";
				// metaImageURL = "https://cdn.ciphersports.io/images/mlb_futures_meta.jpg";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				page_description = "Looking for MLB futures and current World Series odds? Stats Insider has simulated the entire MLB season over 10,000 times to help you pick out a winning bet on the World Series.";
				subheading_description = `<h2>Free MLB World Series Betting Tips</h2>
				<p>Stats Insider has MLB futures predictions for the current baseball season. Based on 1000s of simulations, our computer analyses the data for each and every match for the entirety of the MLB season to determine the likelihood of each team winning the World Series, winning the AL and NL, and winning their division.</p>
				`;
				break;
			case "SRU":
				title = "Super Rugby 2020 Premiership Futures | Ladder Predictor";
				description = "Super Rugby Title probabilities. Where will your team finish at the end of the season?";
				// metaImageURL = "https://cdn.ciphersports.io/images/sru_futures_meta.jpg";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				// page_description = ;
				// subheading_description = ;
				break;
			case "TRC":
				title = "2019: Rugby Championship Futures";
				description = "2019 predictions for The Rugby Championship 2019, built by the Stats Insider machine learning models.";
				metaImageURL = "https://cdn.ciphersports.io/images/default_social.png";
				// page_description = ;
				// subheading_description = ;
				break;
			case "NRL":
				title = "NRL Futures 2024 | NRL Premiership Odds";
				description = "Get the latest NRL Futures 2024 and NRL Premiership Odds. Explore our top-rated NRL futures and NRL premiership odds today.";
				metaImageURL = "https://cdn.ciphersports.io/images/nrl_futures_meta.jpg";
				page_description = "Looking for the latest NRL futures and current NRL premiership odds? Stats Insider has run thousands of simulations for the <a href='/sport-hub/nrl/schedule' target='_blank'>entire NRL season</a> to assist you in your NRL futures betting. If you’ve come to Stats Insider looking to place a bet on the NRL premiership, the top four, the top eight, or the wooden spoon, with Stats Insider's NRL futures and NRL ladder, you can harness the power of machine learning to help you make informed betting decisions.";
				subheading_description = `
					<h2>NRL Premiership Odds and Futures Bets</h2>

					<p>For avid punters, Stats Insider is a go-to resource for NRL premiership odds, NRL futures odds, and everything in between. As the season unfolds, these odds will change based on team performance, injuries, and other factors. However, you can stay ahead of the curve by using Stats Insider's NRL predictive model, which simulates every game of the season 10,000 times. By employing data-driven machine learning, you can use our NRL ladder predictor and NRL premiership odds to help you make strategic futures bets or confirm your opinions on specific teams with the aid of Stats Insider.</p>
					
					<h2>NRL Top 4 and Top 8 Predictions</h2>
					
					<p>Stats Insider's NRL predictive model is an invaluable tool for rugby league fans aiming to project the final ladder standings at the end of the season. Using various factors, our NRL predictive model simulates countless scenarios to see how each team's position on the ladder is affected. This, combined with our <a href='/sport-hub/nrl/sos' target='_blank'>NRL Schedule Difficulty</a> page, provides invaluable data-driven insights on who you should bet on to make the NRL finals and compete for the top four.</p>
					<p>Leveraging Stats Insider's NRL predictions, you can use the results to make the most of the NRL premiership odds and NRL futures odds, making educated predictions about the minor premiership as well as the NRL premiership odds.</p>
				
				`;
				faqs = [
					{headerText: "Which teams are considered the top contenders for the 2024 NRL Premiership?", bodyHTML: "Our NRL futures odds are continually updated with every result of the season. We simulate every game of the NRL season 10,000 times to help you make informed decisions on your NRL futures betting. Check back regularly for Stats Insider's top contenders for the NRL Grand Final, minor premiership, top four, and wooden spoon."},
					{headerText: "What are some dark horse teams that could surprise in the 2024 NRL Premiership season?", bodyHTML: "Stats Insider harnesses the power of machine learning to identify the genuine contenders in the NRL season, including the dark horses that rank highly in NRL futures odds. Our NRL predictive model constantly updates after every game to give you the latest NRL premiership odds."},
					{headerText: "Which teams will be contending for an NRL top-eight berth?", bodyHTML: "Every year, the NRL season brings about new contenders for the NRL premiership, including fresh teams competing for the NRL finals. Stats Insider simulates every game of the NRL season 10,000 times to provide predictions for how the season will play out, including the teams that will be contending for an NRL top-eight berth."},
				];
				break;
			case "AFL":
				title = "2024 AFL Premiership Futures | AFL Ladder Predictor";
				description = "Improve your 2024 AFL Premiership Futures Betting with our AFL Ladder Predictor. Get the latest futures odds and make informed decisions with our expert analysis.";
				metaImageURL = "https://cdn.ciphersports.io/images/afl_futures_meta.jpg";
				page_description = "Looking for AFL futures and current AFL premiership odds? Stats Insider has run thousands of simulations over the <a href='/sport-hub/afl/schedule' target='_blank'>entire AFL season</a> to help with your AFL futures betting. Whether you want to place a bet on the AFL Grand Final, the minor premiership, the top four, or the wooden spoon, with Stats Insider's AFL futures and AFL ladder predictor, you can utilise the power of machine learning to help you make informed betting decisions.";
				subheading_description = `
					<h2>AFL Premiership Odds and Futures Bets</h2>

					<p>For the punters out there, the AFL premiership odds and AFL futures odds offer an exciting opportunity to wager on the outcome of the season. As the season progresses, these odds will fluctuate based on team performance, injuries, and other factors, but you can be ahead of the game using Stats Insider's AFL predictive model, which simulates every game of the season 10,000 times. Those looking to utilise data-driven machine learning are able to use our AFL ladder predictor and AFL premiership odds to help them place their futures bets, or those looking to confirm their opinions on certain teams are able to do so using Stats Insider.</p>

					<h2>AFL Top 4 and Top 8 Predictions</h2>

					<p>Stats Insider's AFL predictive model is one of the best tools to assess your AFL team's finals chances. Using numerous factors, our AFL predictive model simulates a number of scenarios to see what AFL clubs will be competing for the top four and the top eight. Stats Insider simulates the entire AFL season thousands of times to determine which teams will be in the running for the top four and top eight and those that will be out of the running for the AFL finals.</p>

					<h2>Using the AFL Ladder Predictor</h2>

					<p>Stats Insider's AFL ladder predictor is a handy tool for footy enthusiasts looking to project the final ladder standings at the end of the season. Using a number of different factors, our AFL predictive model simulates a multitude of different scenarios to see how each team's position on the ladder is impacted. This, coupled with our <a href='/sport-hub/afl/sos' target='_blank'>AFL Schedule Difficulty</a> page, helps deliver data-driven results on how you should bet on the AFL premiership.</p>
					<p>Using Stats Insider's AFL ladder predictor, you can utilise the results to help you make the most of the AFL premiership odds and AFL futures odds. You can make educated predictions about the minor premiership as well as the AFL premiership odds.</p>
				`;
				faqs = [
					{headerText: "Which teams are considered the top contenders for the 2024 AFL Premiership?", bodyHTML: "Our AFL futures odds are constantly changing with every result of the season. We simulate every game 10,000 times to help you make informed decisions on your AFL futures betting. Check back regularly for Stats Insider's top contenders."},
					{headerText: "What are some dark horse teams that could surprise in the 2024 AFL Premiership season?", bodyHTML: "Stats Insider uses the power of AI and machine learning to decide who the real contenders are in the AFL season, which includes the dark horses that are towards the top of AFL futures odds. Our AFL predictive model constantly updates at the conclusion of every game to give you the latest AFL premiership odds."},
					{headerText: "How can I use the AFL ladder predictor to forecast the outcome of the season?", bodyHTML: "Stats Insider's AFL premiership probabilities are your best AFL ladder predictor, as they show which teams will be competing for the flag. Simulating every game 10,000 times, Stats Insider is able to determine the teams that will be in the mix for the minor premiership, which is the team that will finish at the top of the AFL ladder predictor."},
				];
				break;
			case "NHL":
				title = "National Hockey League Futures | NHL Predictor";
				description = "Stanley Cup win probabilities. Where will your NHL team finish at the end of the season?";
				metaImageURL = "https://cdn.ciphersports.io/images/nhl_futures_meta.jpg";
				page_description = "Looking for NHL futures and current Stanley Cup odds? Stats Insider has simulated the entire NHL season over 10,000 times to help you pick out a winning bet on the Stanley Cup.";
				subheading_description = `<h2>Free Stanley Cup Betting Tips</h2>
				<p>Stats Insider has NHL futures predictions for the current hockey season. Based on 1000s of simulations, our computer analyses the data for each and every match for the remainder of the NHL season to determine the likelihood of each team winning the Stanley Cup, winning their conference and winning their division.</p>
				`;
				break;
			case "MLS":
				title = "MLS Futures | 2024 Major League Soccer Predictor";
				description = "MLS Cup Playoffs and Table probabilities. Where will your MLS club finish at the end of the year?";
				// metaImageURL = "https://cdn.ciphersports.io/images/mlb_futures_meta.jpg";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				page_description = "Looking for Major League Soccer futures and current MLS Cup odds? Stats Insider has simulated the entire MLS season over 10,000 times to help you pick out a winning bet on the MLS Cup.";
				subheading_description = `<h2>Free MLS Championship Betting Tips</h2>
				<p>Stats Insider has MLS futures predictions for the current soccer season. Based on 1000s of simulations, our computer analyses the data for each and every match for the remainder of the Major League Soccer season to determine the likelihood of each team winning the MLS Cup.</p>`;
				break;
			case "SWC":
				title = "2022 FIFA World Cup Futures";
				description = "Model predictions for every 2022 FIFA World Cup match. Who will win? Get the latest probabilities from the home of advanced sporting analytics and machine-learning model predictions.";
				metaImageURL = "https://cdn.ciphersports.io/images/default_meta.jpg";
				break;
			default:
				title = environment.siteTitle;
				description = environment.defaultMetaDescription;
				metaImageURL = environment.defaultMetaImage;
				// page_description = ;
				// subheading_description = ;

		}

		return of({
			page_title: `${sport?.shortName || sportCode.toUpperCase()} Futures`,
			site_title: title,
			seo_title: title,
			seo_description: description,
			thumbnail: {url: metaImageURL},
			page_description: page_description,
			subheading_description: subheading_description,
			faqs: faqs,
		})
	}

	
	// this is for sport-specific version of best bets page
	// TODO
	getSportBestBetsMeta(sportCode: string): Observable<Record<string, any>> {
		if (sportCode.toUpperCase() === "NRL") {
			return of({
				site_title: "NRL Best Bets Today | Best NRL Bets Every Week",
				seo_title: "NRL Best Bets Today | Best NRL Bets Every Week",
				seo_description: "Get the latest NRL Best Bets from our experts and data-driven technology. Stay ahead of the game and make informed decisions based on industry-leading computer learning.",
				page_description: `Are you an NRL fan looking for expert betting tips and predictions for every game in 2024? Stats Insider has you covered. Our data-driven computer learning NRL model uses data analysis tools and techniques to provide the most accurate and reliable tips for every game throughout the season. By following our <a href="/best-bets/nrl" target="_blank">NRL betting tips</a> and predictions, you can increase your chances of winning and finding the best NRL tips for every weekend.`,
				subheading_description: `
					<h2>Top NRL Best Bets This Week</h2>
					<p>You can find Stats Insider's top picks for the best NRL bets this week, carefully selected by our enthusiastic team of analysts. We consider various factors such as team form, head-to-head records, and player injuries to make sure our predictions are as accurate and positive as possible.</p>
					<h2>Expert Analysis for NRL Best Bets</h2>
					<p>Our team of seasoned NRL experts is driven by a passion for the game and a commitment to providing you with the most informed NRL best bets. We don't just look at the numbers; we also consider factors like team news, coaching strategies, and key matchups to bring you the most comprehensive analysis for your betting decisions and <a href="/sport-hub/nrl/tipping" target="_blank">NRL tips</a>.</p>
					<h2>How does our Best NRL Bets work?</h2>
					<p>Stats Insider's best NRL bets works by utilising our industry-leading technology to deliver NRL best bets each and every week. Our data-driven predictive model analyses every game 10,000 times to provide accurate and reliable match predictions across the NRL season. Our Best NRL bets take a multitude of factors into them, including weather, team news, injuries and form coming into the match. Utilising our best NRL bets, you have the optimal chance of getting your NRL bets right.</p>
					<h2>Placing Your Best NRL Bets</h2>
					<p>When it comes to placing your best NRL bets, there are a few key strategies to keep in mind that will make your betting experience more enjoyable and positive. Here are some tips from our experts:</p>
					<p>Do your research: The more informed you are, the better your chances of making successful bets and enjoying the process.</p>
					<p>Manage your bankroll: Set a budget for betting and stick to it, which will ensure you have a positive experience without financial stress.</p>
					<p>Look for value bets: Seek opportunities where the odds might be undervalued, adding excitement and potential for greater returns.</p>
				`,
				faqs: [
					{headerText: "How often are NRL best bets updated?", bodyHTML: "Our industry-leading, analytics-based technology updates upcoming games with information as soon as it happens, and our expert team updates the best NRL bets every week, so be sure to check back regularly for the latest tips and predictions."},
					{headerText: "Can I trust Stats Insider's NRL Best Bets?", bodyHTML: "While our team of experts strives to provide the most accurate predictions possible, the unpredictable nature of sports betting means that no outcome is guaranteed. We trust our model and put incredible resources into them, but remember, we're not telling you who will win, just how a match could potentially play out."},
				]
			});
		}
		if (sportCode.toUpperCase() === "AFL") {
			return of({
				site_title: "AFL Best Bets Today | Best AFL Bets Every Week",
				seo_title: "AFL Best Bets Today | Best AFL Bets Every Week",
				seo_description: "Get the latest AFL Best Bets from our experts and analysts. Maximise your winning chances and stay ahead of the game. Our AFL best bets are the result of computer learning, data-driven analytic modelling that simulates every game over 10,000 times to predict how AFL games may play out. Using our Best AFL bets every week, you'll have the best possible opportunity to find winners this AFL season.",
				page_description: `Welcome to our AFL expert betting tips page for 2024. We simulate every game 10,000 times to deliver data-driven tips and <a href="/best-bets/afl" target="_blank">AFL best bets</a> using computer learning. Whether you're an experienced AFL punter or just starting out, our AFL predictive model delivers objective insights to help you make the best AFL bets.`,
				subheading_description: `
					<h2>This Week's Top AFL Best Bets</h2>
					<p>On this page, Stats Insider showcases our top choices for the best AFL bets this week, meticulously handpicked by our team of analysts. We take into account various factors such as team form, head-to-head records, and player injuries to ensure our predictions are both accurate and reliable.</p>
					<h2>Expert Insights for AFL Best Bets</h2>
					<p>Our team of seasoned AFL specialists delves deep into statistics, past performance, and current form to offer you the most informed AFL best bets and <a href="/sport-hub/afl/tipping" target="_blank">AFL tips for every week</a>. We go beyond the numbers, taking into account aspects like team morale, coaching strategies, and key matchups to provide you with the most comprehensive analysis for your betting decisions.</p>
					<h2>How does Stats Insider's Best AFL Bets work?</h2>
					<p>Using data-driven, computer analytics, Stats Insider's Best AFL Bets are the result of comprehensive modelling on each and every AFL game. Using industry-leading technology, Stats Insider simulates every game 10,000 times to give accurate predictions of how every AFL game should go, so you can make the best AFL bets every week to maximise your chances of winning.</p>
					<h2>Strategies for Placing Your Best AFL Bets</h2>
					<p>When it comes to placing your best AFL bets, consider these key strategies to make your betting experience enjoyable and successful:</p>
					<p>Research thoroughly: Being well-informed increases your chances of making successful bets.</p>
					<p>Manage your bankroll: Set a budget for betting and adhere to it to avoid financial stress.</p>
					<p>Seek value bets: Look for opportunities where the odds may be undervalued, rather than simply betting on the favourites.</p>
					<p>Stay updated on team news: Injuries and other factors can significantly impact game outcomes, so keeping abreast of the latest news is crucial.</p>
				`,
				faqs: [
					{headerText: "How often are AFL best bets updated?", bodyHTML: "Our data-driven, industry-leading technology updates upcoming games with information as soon as it happens, and our expert team updates the best AFL bets every week, so be sure to check back regularly for the latest tips and predictions."},
					{headerText: "Can I trust Stats Insider's AFL Best Bets?", bodyHTML: "While our team of experts strives to provide the most accurate predictions possible, sports betting can be unpredictable. Remember, Stats Insider's AFL best bets are the result of 10,000 simulations, and while we trust our modelling, it's a reflection on how a match could play out, not how it exactly will."},
				]
			})
		}

		
		const sport = this.localisationService.sportPrioritySort(SPORTS).find(s => s.code.toUpperCase() === sportCode.toUpperCase());

		return of({
			site_title: `${sport?.shortName || sportCode.toUpperCase()} Best Bets Today`,
			seo_title: `${sport?.shortName || sportCode.toUpperCase()} Best Bets Today`,
			seo_description: `Get the latest ${sport?.shortName || sportCode.toUpperCase()} Best Bets from our experts and analysts.`,
			page_description: undefined,
			subheading_description: undefined,
		});
	}

	// this is for sport-specific version of best props page 
	// TODO
	getSportBestPropsMeta(sportCode: string): Observable<Record<string, any>> {
		return of({});
	}

	// TODO
	getStateSettings(stateCode?: string): Observable<Array<Record<string, any>>> {
		console.log('getStateSettings');
		return of([]);
	}

	// TODO
	getBestBooksData(stateCode?: string): Observable<Array<Record<string, any>>> {
		console.log('getBestBooksData');
		return of([]);
	}

	// TODO
	getBookmakerData(bookmakerCode: string, stateCode?: string): Observable<Record<string, any>> {
		console.log('getBookmakerData');
		return of({});
	}

	// TODO
	getBookmakerPromo(id: string): Observable<Record<string, any>> {
		console.log('getBookmakerPromo');
		return of({});
	}

	// TODO
	getHyperlink(id: string): Observable<Record<string, any>> {
		console.log('getHyperlink');
		return of({});
	}

	// TODO
	getPodcastInformation(): Observable<Record<string, any>> {
		console.log('getPodcastInformation');
		return of({});
	}

	// TODO
	getPodcastList(): Observable<Array<Record<string, any>>> {
		console.log('getPodcastList')
		return of([])
	}

	
	// TODO
	getFreeToPlayData(): Observable<Record<string, any>> {
		console.log('getFreeToPlayData');
		return of({});
	}

	// TODO
	addNewsletterEmail(email: string, firstName?: string): Observable<boolean> {
		console.log('getting geo data addNewsletterEmail()');
		return of(false);
	}

	// TODO
	getBettingExplainedCategories(): Observable<Array<Record<string, any>>> {
		console.log('getBettingExplainedCategories()');
		return of([]);
	}

	// TODO
	getBettingExplainedCategory(slug: string): Observable<Record<string, any>> {
		console.log('getBettingExplainedCategory()');
		return of({});

	}

	// TODO
	getMatchPagePromos(matchID: string): Observable<Array<Record<string, any>>> {
		console.log('getMatchPagePromos()');
		return of([]);
	}

	// TODO
	getBettingExplainedArticle(slug: string): Observable<BlogArticle> {
		console.log('getBettingExplainedArticle()');
		return of(null);

	}

	// TODO
	getOnboardingBookmakers(): Observable<Array<Record<string, any>>> {
		console.log('getOnboardingBookmakers()');
		return of([]);
	}

	searchBettingExplainedArticles(searchTerm: string): Observable<Array<BlogArticle>> {
		console.log('searchBettingExplainedArticles()');
		return of([]);
	}

	sessionIncrementer = 1;

	getIncrement(): number {
		console.log('getIncrement()');
		this.sessionIncrementer++;
		return this.sessionIncrementer;
	}

	getSportsBettingPoll(id: string): Observable<Record<string, any>> {
		console.log('getSportsBettingPoll()');
		return of({});
	}

	getResponsiveBanner(id: number): Observable<Record<string, any>> {
		console.log('getResponsiveBanner()');
		return of({});
	}



	// timeAgoString(date: Date): string {
	// 	let hoursago = differenceInMilliseconds(date, new Date()) / (1000 * 3600) * -1;
	// 	if (hoursago < 1) {
	// 		// minutes ago
	// 		var minutesago = hoursago * 60;
	// 		return minutesago.toFixed(0) + (minutesago.toFixed(0) == "1" ? " minute ago" : " minutes ago");
	// 	} else if (hoursago < 24) {
	// 		// hours ago
	// 		return hoursago.toFixed(0) + (hoursago.toFixed(0) == "1" ? " hour ago" : " hours ago");
	// 	} else if (hoursago < (24 * 7)) {
	// 		// days ago
	// 		var daysago = hoursago / 24;
	// 		return daysago.toFixed(0) + (daysago.toFixed(0) == "1" ? " day ago" : " days ago");
	// 	} else if (isThisYear(date)) {
	// 		return format(date, "d MMMM")
	// 	} else {
	// 		return format(date, "d MMMM yyyy")
	// 	}
	// }

	convertCategorySlug(categorySlug: string): string {
		return SPORTS.find(s => s.code === categorySlug.toUpperCase())?.shortName || categorySlug.toUpperCase();
	}

	

	private handleError<T>() {
		return (error: any): Observable<T> => {
			return throwError(new Error("CMS_CALL_FAILED " + JSON.stringify(error, ["message", "arguments", "type", "name"])));
		}
	}

}
